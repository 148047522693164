<script setup>
import { defineProps, computed, defineEmits } from 'vue';
import { useStore } from 'vuex';

const props = defineProps(['data', 'id']);

const emit = defineEmits(['doGoal']);

const store = useStore();

const org = computed(() => store.state.profile.current_org);

const goal_added = computed(() => store.getters.goalAdded);
</script>

<template>
	<table class="min-w-full divide-y text-neutral-200">
		<thead class="bg-neutral-100 h-16">
			<tr></tr>
			<tr>
				<th
					scope="col"
					class="
						px-6
						py-3
						text-left text-xs
						font-semibold
						text-neutral-600
						uppercase
						tracking-wider
						w-8
						whitespace-nowrap
					"
				></th>
				<th
					scope="col"
					class="
						px-6
						py-3
						text-left text-xs
						font-semibold
						text-neutral-600
						uppercase
						tracking-wider
						w-16
						whitespace-nowrap
					"
				>
					STT
				</th>
				<th
					scope="col"
					class="
						px-6
						py-3
						text-left text-xs
						font-semibold
						text-neutral-600
						uppercase
						tracking-wider
						whitespace-nowrap
					"
					style="min-width: 160px"
				>
					MỤC TIÊU CỤ THỂ
				</th>
				<th class="px-6 py-3 w-max"></th>
			</tr>
		</thead>
		<tbody>
			<tr
				v-for="(g, i) in props.data"
				:key="g.id"
				class="text-neutral-700"
			>
				<td class="px-6 py-4 w-8 divTableCell">
					<div class="w-4">
						<img
							class="w-full"
							src="@/assets/images/icons/child-goal.png"
							alt=""
						/>
					</div>
				</td>
				<td class="px-6 py-4 whitespace-nowrap text-neutral-700">
					<div class="ml-2">
						{{ i + 1 }}
					</div>
				</td>
				<td class="px-6 py-4 text-neutral-700 w-full">
					<div class="flex items-center gap-1">
						<router-link :to="`/goal/${props.id}/${g.goal_id}`">
							<div
								class="hover:text-yellow-700 hover:underline"
								v-html="g.goal_name || g.name"
							></div>
						</router-link>
						<TooltipGoalDetail
							:goal-id="g.goal_id || g.id"
							:student-id="props.id"
						/>
					</div>
					<div
						class="flex gap-2 flex-wrap"
						style="max-width: 360px"
						v-if="
							(g?.assistance_levels &&
								org.enable_goal_assistance_level) ||
							g?.raw_source
						"
					>
						<template
							v-if="
								g?.assistance_levels &&
								org.enable_goal_assistance_level
							"
							class="flex gap-2"
						>
							<div v-for="ass in g?.assistance_levels" :key="ass">
								<div
									class="
										mt-1
										py-0.5
										px-2.5
										rounded-full
										bg-pink-100
										whitespace-nowrap
										text-neutral-700
										h-fit
										w-fit
										font-semibold
										shadow-input
									"
									style="font-size: 10px"
								>
									{{ ass }}
								</div>
							</div>
						</template>
						<div
							class="
								mt-1
								py-0.5
								px-2.5
								rounded-full
								bg-yellow-tag
								text-neutral-700
								h-fit
								w-fit
								font-semibold
								shadow-input
								whitespace-nowrap
							"
							style="font-size: 10px"
							v-if="g?.age"
						>
							{{ g?.age }}
						</div>
					</div>
				</td>
				<td class="py-4 pr-6">
					<button
						v-if="
							goal_added.includes(g.id) || g.state !== 'library'
						"
						type="button"
						class="
							button-p
							btn--text
							whitespace-nowrap
							text-green-600
						"
					>
						<svg
							class="mr-1"
							width="20"
							height="20"
							viewBox="0 0 20 20"
							fill="none"
							xmlns="http://www.w3.org/2000/svg"
						>
							<path
								fill-rule="evenodd"
								clip-rule="evenodd"
								d="M11.212 6.21209C11.5782 5.84597 12.1717 5.84597 12.5379 6.21209C12.8995 6.57371 12.9039 7.15727 12.5512 7.52434L7.56092 13.7622C7.55371 13.7712 7.54602 13.7798 7.53787 13.7879C7.17175 14.154 6.57816 14.154 6.21204 13.7879L2.90398 10.4799C2.53787 10.1137 2.53787 9.52015 2.90398 9.15403C3.2701 8.78791 3.86369 8.78791 4.22981 9.15403L6.84671 11.7709L11.1872 6.24013C11.1949 6.23029 11.2032 6.22093 11.212 6.21209Z"
								fill="#2AA797"
							/>
							<path
								fill-rule="evenodd"
								clip-rule="evenodd"
								d="M10.0615 12.6374L11.212 13.7879C11.5782 14.154 12.1718 14.154 12.5379 13.7879C12.546 13.7798 12.5537 13.7712 12.5609 13.7622L17.5512 7.52434C17.9039 7.15727 17.8995 6.57371 17.5379 6.21209C17.1717 5.84597 16.5782 5.84597 16.212 6.21209C16.2032 6.22093 16.1949 6.23029 16.1872 6.24013L11.8467 11.7709L11.24 11.1642L10.0615 12.6374Z"
								fill="#2AA797"
							/>
						</svg>
						<div class="text-green-600">THÊM VÀO KH</div>
					</button>
					<button
						v-else
						type="button"
						class="button-p btn--text whitespace-nowrap"
						@click="emit('doGoal', g.id)"
					>
						Dạy MT
					</button>
				</td>
			</tr>
		</tbody>
	</table>
</template>
