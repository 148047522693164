<script setup>
import { computed, defineProps, defineEmits, ref } from 'vue';
import { useStore } from 'vuex';
import Checkbox from '@/components/elements/checkbox/Checkbox';
import dayjs from 'dayjs';

const checkParent = ref(false);

const store = useStore();

const mapData = computed(() => props.data?.sub_items.map((i) => i.id));

const contains = computed(() =>
	mapData.value.some((element) => goalsSelected.value.includes(element))
);

const goalTaught = computed(() => store.getters.goalTaught);
console.log(goalTaught);
const goalsSelected = computed(() => {
	const s = store.getters.goalsSelected;
	return (s || []).map((i) => i.id);
});

const props = defineProps([
	'data',
	'foldersSelected',
	'isInCreateGoal',
	'isAdmin',
	'checkParent',
]);

const emit = defineEmits(['action-clicked']);

const checkStatus = (goal) => {
	return goalsSelected.value.find((i) => +i === +goal.id);
};

const check = (goals, isAll) => {
	store.dispatch('selected', { goals, isAll });
};

const checkChanged = (goals, isAll) => {
	if (contains.value) checkParent.value = true;
	check(goals, isAll);
	if (contains.value && !checkParent.value) {
		check([props.data], isAll);
		checkParent.value = true;
	}
	if (!contains.value) {
		check([props.data], isAll);
		checkParent.value = false;
	}
};
</script>
<template>
	<table
		class="min-w-full divide-y text-neutral-200 mb-5 border-table bg-white"
	>
		<thead class="bg-neutral-100 h-16">
			<tr>
				<th
					scope="col"
					class="
						px-6
						py-3
						text-left text-xs
						font-semibold
						text-neutral-600
						uppercase
						tracking-wider
						w-16
						whitespace-nowrap
					"
				></th>
				<th
					v-if="!props.isInCreateGoal && props.isAdmin"
					scope="col"
					class="
						px-6
						py-3
						text-left text-xs
						font-semibold
						text-neutral-600
						uppercase
						tracking-wider
						w-16
						whitespace-nowrap
					"
				></th>
				<th
					scope="col"
					class="
						px-6
						py-3
						text-left text-xs
						font-semibold
						text-neutral-600
						uppercase
						tracking-wider
						w-16
						whitespace-nowrap
					"
				>
					STT
				</th>
				<th
					scope="col"
					class="
						px-6
						py-3
						text-left text-xs
						font-semibold
						text-neutral-600
						uppercase
						tracking-wider
						whitespace-nowrap
					"
				>
					Mục tiêu cụ thể
				</th>
				<th
					v-if="props.isInCreateGoal"
					scope="col"
					class="
						px-6
						py-3
						text-left text-xs
						font-semibold
						text-neutral-600
						uppercase
						tracking-wider
						whitespace-nowrap
					"
				></th>
			</tr>
		</thead>
		<tbody style="border-top: none">
			<tr
				v-for="(g, i) in props.data?.sub_items"
				:key="g.id"
				class="bg-white text-neutral-700 border-table"
			>
				<td class="px-6 py-4 w-16">
					<div class="w-4">
						<img
							class="w-full"
							src="@/assets/images/icons/child-goal.png"
							alt=""
						/>
					</div>
				</td>
				<td
					class="px-6 py-4 w-16"
					v-if="!props.isInCreateGoal && props.isAdmin"
				>
					<Checkbox
						:disabled="
							props.foldersSelected?.length > 0 ? true : false
						"
						:value="checkStatus(g)"
						@click="
							!props.foldersSelected?.length &&
								checkChanged([g], false)
						"
					/>
				</td>
				<td class="px-6 py-4 whitespace-nowrap text-neutral-700">
					<div class="ml-2">{{ i + 1 }}</div>
				</td>
				<td class="px-6 py-4 text-neutral-700">
					<div v-html="g?.name"></div>
					<div
						class="
							mt-1
							py-0.5
							px-2.5
							rounded-full
							bg-yellow-tag
							text-neutral-700
							h-fit
							w-fit
							font-semibold
							shadow-input
							whitespace-nowrap
						"
						style="font-size: 10px"
						v-if="g?.age"
					>
						{{ g?.age }}
					</div>
					<!-- {{ g }}
					{{ goalTaught }}
					{{ goalTaught.includes(g?.library_item_id) }}
					{{ g?.library_item_id }} -->

					<div
						v-if="g?.used || goalTaught.includes(g?.id)"
						class="flex gap-2 mt-1"
					>
						<svg
							width="16"
							height="16"
							viewBox="0 0 16 16"
							fill="none"
							xmlns="http://www.w3.org/2000/svg"
						>
							<path
								fill-rule="evenodd"
								clip-rule="evenodd"
								d="M8.96966 4.96967C9.26256 4.67678 9.73743 4.67678 10.0303 4.96967C10.3196 5.25897 10.3232 5.72582 10.041 6.01947L6.04876 11.0097C6.043 11.0169 6.03685 11.0238 6.03032 11.0303C5.73743 11.3232 5.26256 11.3232 4.96966 11.0303L2.32322 8.38388C2.03032 8.09099 2.03032 7.61612 2.32322 7.32322C2.61611 7.03033 3.09098 7.03033 3.38388 7.32322L5.4774 9.41674L8.94977 4.9921C8.95595 4.98424 8.96259 4.97674 8.96966 4.96967Z"
								fill="#2AA797"
							/>
							<path
								fill-rule="evenodd"
								clip-rule="evenodd"
								d="M8.04924 10.1099L8.96966 11.0303C9.26256 11.3232 9.73743 11.3232 10.0303 11.0303C10.0368 11.0238 10.043 11.0169 10.0488 11.0097L14.041 6.01947C14.3232 5.72582 14.3196 5.25897 14.0303 4.96967C13.7374 4.67678 13.2626 4.67678 12.9697 4.96967C12.9626 4.97674 12.9559 4.98424 12.9498 4.9921L9.4774 9.41674L8.99205 8.9314L8.04924 10.1099Z"
								fill="#2AA797"
							/>
						</svg>
						<span
							v-if="g?.used_at"
							class="font-semibold text-green-500 text-xs"
							>{{ $filters.formatDate(g?.used_at) }}</span
						>
						<span
							v-else-if="goalTaught.includes(g?.id)"
							class="font-semibold text-green-500 text-xs"
							>{{ dayjs(Date.now()).format('DD/MM/YYYY') }}</span
						>
					</div>
				</td>
				<td class="w-12" v-if="props.isInCreateGoal">
					<div
						@click="emit('action-clicked', g)"
						class="
							px-4
							cursor-pointer
							text-yellow-600
							hover:text-yellow-700
							font-semibold
							text-sm
							flex
							items-center
							md-down:items-right
							whitespace-nowrap
						"
					>
						Thêm MT
					</div>
				</td>
			</tr>
		</tbody>
	</table>
</template>
<style scoped>
.border-table {
	border: 1px solid #f1f5f9;
	border-collapse: collapse;
}
</style>
