
import { defineComponent, onMounted, ref, watch } from 'vue';
import { $api } from '@/services';
import { useRoute, useRouter } from 'vue-router';
import DaIcon from '@/components/elements/daIcon/DaIcon.vue';
import { timeSinceHour } from '@/helper/formatDate';
import ViewPhoto from '@/components/common/ViewPhoto.vue';

export default defineComponent({
	components: { ViewPhoto, DaIcon },
	setup() {
		const route = useRoute();
		const router = useRouter();
		const reportDetail = ref(null as any);
		const series_ = ref([] as any);
		const seriesData = ref([]);
		const monthly_start = ref(route.query.month_start as string);
		const studentId = ref(route.params.id);
		const goalId = ref(route.params.goalId);
		const report = ref(null as any);
		const goal = ref(null as any);
		const isLoaded = ref(false);

		async function fetchGoalAssignmentDetail() {
			try {
				const { data } = await $api.monthlyReport.assessmentReports(
					studentId.value + '',
					report.value.id,
					goalId.value + ''
				);
				reportDetail.value = data.data;
				goal.value = reportDetail.value?.assessment_report?.goal;
				seriesData.value = await data.data?.assessments.map(
					(item: any) => {
						return +item.complete_percent;
					}
				);
				series_.value = [
					{
						name: 'Kết quả buổi học',
						data: seriesData.value,
					},
				];
			} catch (e) {
				console.log(e);
			}
		}

		async function fetchReport() {
			isLoaded.value = false;
			try {
				const { data } = await $api.monthlyReport.monthlyReport(
					studentId.value + '',
					monthly_start.value
				);
				if (data) {
					report.value = data.data;
					await fetchGoalAssignmentDetail();
				}
				isLoaded.value = true;
			} catch (e) {
				isLoaded.value = true;
				console.log(e);
			}
		}

		function exit() {
			router.push(
				`/report/monthly-report/${studentId.value}?month_start=${monthly_start.value}`
			);
		}

		function reason() {
			const as = reportDetail.value?.assessment_report;
			const stopped_reason =
				reportDetail.value?.assessment_report?.stopped_reason || '';
			const stopped_reason_other =
				reportDetail.value?.assessment_report?.stopped_reason_other ||
				'';
			const other =
				stopped_reason !== 'Khác' && stopped_reason !== 'other'
					? stopped_reason.toLowerCase()
					: stopped_reason_other.toLowerCase();
			return as?.next_plan !== 'Tạm dừng mục tiêu'
				? as?.next_plan
				: `${as?.next_plan} vì ${other}`;
		}

		function countMedia(g: any) {
			return g?.goal_media >= 6 ? 6 : g?.goal_media?.length;
		}

		const imageShow = ref(false);
		const photosIndex = ref(0);
		const photos = ref([]);
		const noArray = ref([]);

		function openMediaPopup(media: any, index: any) {
			imageShow.value = true;
			photos.value = media;
			photosIndex.value = index;
		}

		function haveMediaByLessonId(lessonId: any) {
			return goal.value?.goal_media?.find(
				(item: any) => item.lesson_report_id === lessonId
			);
		}

		function loadThumbnail(asset: any) {
			const imageRequest = JSON.stringify({
				bucket: asset.image_bucket,
				key: asset.video_poster_asset_key,
				edits: {
					width: 400,
					height: 400,
				},
			});
			return `${asset.image_cloudfront_url}/${btoa(imageRequest)}`;
		}

		function loadImage(asset: any) {
			const imageRequest = JSON.stringify({
				bucket: asset.image_bucket,
				key: asset.asset_key,
				edits: {
					width: 400,
					height: 400,
				},
			});
			return `${asset.image_cloudfront_url}/${btoa(imageRequest)}`;
		}

		const scrollable = ref(false);
		onMounted(() => {
			window.scrollTo(0, 0);
			setTimeout(() => {
				const feedbackBox = document.getElementById('feedback-box');
				if (feedbackBox) {
					scrollable.value =
						feedbackBox?.scrollWidth > feedbackBox?.clientWidth;
				}
			}, 3000);
		});

		watch(
			isLoaded,
			() => {
				if (isLoaded.value) {
					setTimeout(() => {
						const feedbackBox =
							document.getElementById('feedback-box');
						if (feedbackBox) {
							scrollable.value =
								feedbackBox?.scrollWidth >
								feedbackBox?.clientWidth;
						}
					}, 1000);
				}
			},
			{
				immediate: true,
			}
		);

		const lineChart = ref(null as any);

		watch(
			lineChart,
			() => {
				setTimeout(() => {
					lineChart.value?.updateOptions({
						labels: '',
					});
				}, 1000);
			},
			{ immediate: true, deep: true }
		);

		fetchReport();

		function scrollLeft() {
			const feedbackBox = document.getElementById('feedback-box');
			if (feedbackBox) {
				const position = feedbackBox.scrollLeft;
				feedbackBox.scrollTo({
					left: position - 800,
					behavior: 'smooth',
				});
			}
		}
		function scrollRight() {
			const feedbackBox = document.getElementById('feedback-box');
			if (feedbackBox) {
				const position = feedbackBox.scrollLeft;
				feedbackBox.scrollTo({
					left: position + 800,
					behavior: 'smooth',
				});
			}
		}

		return {
			goal,
			scrollable,
			timeSinceHour,
			scrollLeft,
			scrollRight,
			noArray,
			imageShow,
			countMedia,
			openMediaPopup,
			haveMediaByLessonId,
			loadThumbnail,
			loadImage,
			photos,
			photosIndex,
			report,
			reason,
			reportDetail,
			series_,
			chartOptions_: {
				colors: ['#2AA797'],
				chart: {
					type: 'line',
					zoom: {
						enabled: false,
					},
					animations: {
						enabled: false,
					},
				},
				stroke: {
					width: 3,
				},
				yaxis: {
					labels: {
						show: false,
					},
					max: 100,
					min: 0,
					tickAmount: 5,
				},
				grid: {
					show: true,
					borderColor: '#F1F5F9',
				},
				markers: {
					size: 8,
					strokeColors: '#fff',
					strokeWidth: 2,
				},
			},
			studentId,
			monthly_start,
			exit,
		};
	},
});
