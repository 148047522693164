<template>
	<div class="fixed inset-y-0 right-0 flex side-over z-50 side-over">
		<div class="h-full w-full flex flex-col bg-white shadow-xl">
			<div class="px-6 py-8 bg-blue-50">
				<div class="flex items-start justify-between">
					<div class="flex items-center" @click="$emit('close')">
						<svg
							fill="none"
							height="24"
							viewBox="0 0 24 24"
							width="24"
							xmlns="http://www.w3.org/2000/svg"
						>
							<path
								d="M22.9638 2.10721L15.9765 21.6157C15.8931 21.8462 15.6743 21.9999 15.4291 22H15.2428C15.0141 22 14.8058 21.8686 14.7071 21.6622L11.9588 15.8388C11.5541 14.9803 11.7107 13.9621 12.3547 13.2649L15.8484 9.44472C16.0628 9.21976 16.0628 8.86604 15.8484 8.64108L15.4058 8.1985C15.1809 7.98406 14.8272 7.98406 14.6023 8.1985L10.7826 11.6926C10.0855 12.3366 9.06733 12.4933 8.20889 12.0885L2.38611 9.33989C2.16156 9.25534 2.00994 9.04397 2.00181 8.80414V8.61779C1.98119 8.35574 2.13865 8.11236 2.38611 8.0238L21.8924 1.0357C22.1021 0.958688 22.3374 1.00847 22.498 1.16382L22.8008 1.46664C22.9828 1.62564 23.0476 1.88054 22.9638 2.10721Z"
								fill="#94A3B8"
							/>
						</svg>
						<div class="text-2xl font-bold text-neutral-700 ml-2">
							Gửi Kết quả can thiệp
						</div>
					</div>
					<div class="ml-3 h-7 flex items-center">
						<button
							class="
								rounded-md
								text-neutral-400
								hover:text-neutral-700
							"
							type="button"
							@click="$emit('close')"
						>
							<span class="sr-only">Close panel</span>
							<svg
								aria-hidden="true"
								class="h-6 w-6"
								fill="none"
								stroke="currentColor"
								viewBox="0 0 24 24"
								xmlns="http://www.w3.org/2000/svg"
							>
								<path
									d="M6 18L18 6M6 6l12 12"
									stroke-linecap="round"
									stroke-linejoin="round"
									stroke-width="2"
								/>
							</svg>
						</button>
					</div>
				</div>
			</div>
			<div class="sideover-content mt-6 relative flex-1 px-4 sm:px-6">
				<div class="w-full mt-5">
					<div class="font-semibold text-neutral-600 mb-2">
						Tên bản đánh giá*
					</div>
					<div class="select-tabs mb-6">
						<div class="input-group flex items-center">
							<input
								v-model="result.name"
								:class="errorName ? 'text-error' : ''"
								maxlength="100"
								placeholder="vd: đánh giá đầu vào, lượng giá...."
								type="text"
							/>
						</div>
						<div class="text-neutral-400 text-sm mt-2">
							{{ result.name.length }}/100 ký tự
						</div>
						<div
							v-if="errorName"
							class="text-sienna-600 text-sm mt-2"
						>
							Tên {{ errorName }}
						</div>
					</div>
				</div>
				<div class="w-full mt-5">
					<div class="font-semibold text-neutral-600 mb-2">
						Ngày thực hiện*
					</div>
					<div class="select-tabs mb-6">
						<div class="input-group w-full">
							<VueDatepicker
								v-model="result.assessment_date"
								:class="errorCreatedTime ? 'text-error' : ''"
								:enableTimePicker="false"
								:maxDate="new Date()"
								autoApply
								class="input-date w-full"
								format="dd/MM/yyyy"
								placeholder="Ngày thực hiện đánh giá"
								uid="filter-by-date"
							>
								<template #input-icon>
									<svg
										fill="none"
										height="20"
										viewBox="0 0 20 20"
										width="20"
										xmlns="http://www.w3.org/2000/svg"
									>
										<path
											clip-rule="evenodd"
											d="M5 0.625C5 0.279822 4.72018 0 4.375 0C4.02982 0 3.75 0.279822 3.75 0.625V1.25H2.5C1.11929 1.25 0 2.36929 0 3.75V5H20V3.75C20 2.36929 18.8807 1.25 17.5 1.25H16.25V0.625C16.25 0.279822 15.9702 0 15.625 0C15.2798 0 15 0.279822 15 0.625V1.25H5V0.625ZM20 17.5V6.25H0V17.5C0 18.8807 1.11929 20 2.5 20H17.5C18.8807 20 20 18.8807 20 17.5ZM11.875 8.75C11.5298 8.75 11.25 9.02982 11.25 9.375V10.625C11.25 10.9702 11.5298 11.25 11.875 11.25H13.125C13.4702 11.25 13.75 10.9702 13.75 10.625V9.375C13.75 9.02982 13.4702 8.75 13.125 8.75H11.875ZM15.625 8.75C15.2798 8.75 15 9.02982 15 9.375V10.625C15 10.9702 15.2798 11.25 15.625 11.25H16.875C17.2202 11.25 17.5 10.9702 17.5 10.625V9.375C17.5 9.02982 17.2202 8.75 16.875 8.75H15.625ZM2.5 13.125C2.5 12.7798 2.77982 12.5 3.125 12.5H4.375C4.72018 12.5 5 12.7798 5 13.125V14.375C5 14.7202 4.72018 15 4.375 15H3.125C2.77982 15 2.5 14.7202 2.5 14.375V13.125ZM6.875 12.5C6.52982 12.5 6.25 12.7798 6.25 13.125V14.375C6.25 14.7202 6.52982 15 6.875 15H8.125C8.47018 15 8.75 14.7202 8.75 14.375V13.125C8.75 12.7798 8.47018 12.5 8.125 12.5H6.875Z"
											fill="#94A3B8"
											fill-rule="evenodd"
										/>
									</svg>
								</template>
							</VueDatepicker>
						</div>
						<div
							v-if="errorCreatedTime"
							class="text-sienna-600 text-sm mt-2"
						>
							Ngày thực hiện
							<span v-html="errorCreatedTime"></span>
						</div>
					</div>
				</div>
				<div class="w-full mt-5">
					<div class="font-semibold text-neutral-600 mb-2">
						Kết luận
					</div>
					<div
						v-for="(a, index) in assessment_conclusions.length"
						:key="index"
						class="select-tabs mb-6 flex items-center gap-6"
					>
						<Multiselect
							v-if="optionResult.length > 0"
							v-model="assessment_conclusions[index]"
							:options="
								optionResult.filter(
									(s) => !assessment_conclusions.includes(s)
								)
							"
							:searchable="true"
							deselectLabel="Ấn đễ bỏ chọn"
							placeholder="Kết luận về tình trạng của h.s"
							selectLabel=""
							selectedLabel=""
						>
							<template #noResult>
								Không tìm thấy kết quả
							</template>
						</Multiselect>
						<DaIcon
							:class="
								assessment_conclusions.length > 1 &&
								index !== assessment_conclusions.length
									? 'text-yellow-600 hover:text-yellow-700 active:text-yellow-500 cursor-pointer'
									: 'text-neutral-300'
							"
							class="h-4 w-4"
							name="das--delete"
							@click="
								assessment_conclusions.length > 1 &&
									index !== assessment_conclusions.length &&
									removeConclusions(index)
							"
						/>
					</div>
					<div class="flex justify-end gap-6">
						<div
							class="
								font-semibold
								text-yellow-700
								mb-2
								text-sm text-right
								cursor-pointer
							"
							@click="addConclusions"
						>
							Thêm chẩn đoán
						</div>
						<div class="h-4 w-4" style="min-width: 8px"></div>
					</div>
				</div>
				<div class="w-full mt-5">
					<div class="font-semibold text-neutral-600 mb-2">
						Tuổi phát triển
					</div>
					<div class="select-tabs mb-6">
						<div
							v-for="(d, index) in development_ages.length"
							:key="index"
							class="
								input-group
								flex
								items-center
								mb-4
								relative
								gap-6
							"
						>
							<input
								v-model="development_ages[index]"
								placeholder="Tuổi phát triển của h.s ở thời điểm đánh giá"
								style="padding-right: 30px"
								type="text"
							/>
							<DaIcon
								:class="
									development_ages.length > 1 &&
									index !== development_ages.length
										? 'text-yellow-600 hover:text-yellow-700 active:text-yellow-500 cursor-pointer'
										: 'text-neutral-300'
								"
								class="h-4 w-4"
								name="das--delete"
								@click="
									development_ages.length > 1 &&
										index !== development_ages.length &&
										removeAge(index)
								"
							/>
						</div>
					</div>
					<div class="flex justify-end gap-6">
						<div
							class="
								font-semibold
								text-yellow-700
								mb-2
								text-sm text-right
								cursor-pointer
							"
							@click="addAge"
						>
							Thêm tuổi phát triển
						</div>
						<div class="h-4 w-4" style="min-width: 8px"></div>
					</div>
				</div>
				<div class="w-full mt-5">
					<div class="font-semibold text-neutral-600 mb-2">
						Đơn thuốc
					</div>
					<div class="select-tabs mb-6">
						<div
							v-for="(p, index) in prescription.length"
							:key="index"
							class="
								input-group
								flex
								items-center
								mb-4
								relative
								gap-6
							"
						>
							<input
								v-model="prescription[index]"
								placeholder="Đơn thuốc của h.s ở thời điểm đánh giá"
								style="padding-right: 30px"
								type="text"
							/>
							<DaIcon
								:class="
									prescription.length > 1 &&
									index !== prescription.length
										? 'text-yellow-600 hover:text-yellow-700 active:text-yellow-500 cursor-pointer'
										: 'text-neutral-300'
								"
								class="h-4 w-4"
								name="das--delete"
								@click="
									prescription.length > 1 &&
										index !== prescription.length;
									removePescription(index);
								"
							/>
						</div>
					</div>
					<div class="flex justify-end gap-6">
						<div
							class="
								font-semibold
								text-yellow-700
								mb-2
								text-sm text-right
								cursor-pointer
							"
							@click="addPescription"
						>
							Thêm đơn thuốc
						</div>
						<div class="h-4 w-4" style="min-width: 8px"></div>
					</div>
				</div>
				<div class="w-full mt-5">
					<div class="font-semibold text-neutral-600 mb-2">
						Nhận xét
					</div>
					<div>
						<textarea
							v-model="result.note"
							placeholder="Nhận xét"
							style="
								height: 100%;
								resize: none;
								min-height: 100px;
							"
						></textarea>
					</div>
				</div>
			</div>
			<div class="px-6 py-4 border-t border-yellow-50">
				<div class="flex justify-end">
					<div>
						<button
							:disabled="!result.name || !result.assessment_date"
							class="button-p text-center w-32"
							type="button"
							@click="toPreview"
						>
							Xem trước
						</button>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { Options, Vue } from 'vue-class-component';
import Multiselect from '@suadelabs/vue3-multiselect';
import { PropSync, Watch } from 'vue-property-decorator';
import AlertUpload from '../../../../../components/elements/alert/AlertUpload';
import DaIcon from '@/components/elements/daIcon/DaIcon.vue';

@Options({
	name: 'UpdateResultSideOver',
	components: { AlertUpload, Multiselect, DaIcon },
})
export default class UpdateResultSideOver extends Vue {
	@PropSync('refresh')
	refresh;

	result = {
		id: '',
		name: '',
		assessment_date: new Date(),
		note: '',
	};

	assessment_conclusions = [''];
	development_ages = [''];
	prescription = [''];

	optionResult = [
	'RL phát triển trí tuệ',
    'RL ngôn ngữ',
    'RL phát âm',
    'RL phổ tự kỷ',
    'RL tăng động/ giảm chú ý',
    'RL điều hòa phát triển',
    'RL vận động định hình',
    'RL Tic'
		// 'Sa sút trí tuệ trong bệnh Alzheimer',
		// 'Sa sút trí tuệ trong bệnh mạch máu',
		// 'Sa sút trí tuệ trong các bệnh lý khác được xếp loại ở chỗ khác',
		// 'Sa sút trí tuệ không biệt định',
		// 'Rối loạn tâm thần do tổn thương, rối loạn chức năng não và bệnh lý cơ thể',
		// 'Rối loạn tâm thần do rượu',
		// 'Các rối loạn tâm thần và hành vi do sử dụng các chất có thuốc phiện',
		// 'Các rối loạn tâm thần và hành vi do sử dụng cần sa',
		// 'Các rối loạn tâm thần và hành vi do sử dụng các chất gây ảo giác',
		// 'Tâm thần phân liệt',
		// 'Rối loạn loại phân liệt',
		// 'Rối loạn hoang tưởng dai dẳng',
		// 'Rối loạn phân liệt cảm xúc',
		// 'Rối loạn cảm xúc lưỡng cực',
		// 'Trầm cảm',
		// 'Rối loạn trầm cảm tái diễn',
		// 'Các trạng thái rối loạn khí sắc',
		// 'Các rối loạn lo âu ám ảnh sợ hãi',
		// 'Các rối loạn lo âu khác',
		// 'Rối loạn ám ảnh cưỡng chế',
		// 'Các rối loạn dạng cơ thể',
		// 'Rối loạn nhân cách và hành vi ở người thành niên',
		// 'Chậm phát triển tâm thần',
		// 'Các rối loạn về phát triển tâm lý',
		// 'Các rối loạn hành vi và cảm xúc thường khởi phát ở tuổi trẻ em và thanh thiếu niên',
		// 'Rối loạn phát triển trí tuệ',
		// 'Rối loạn nhận thức thần kinh',
		// 'Rối loạn học tập biệt định và rối loạn giao tiếp',
		// 'Rối loạn giao tiếp',
		// 'Rối loạn ngôn ngữ',
		// 'Biến thể bình thường của ngôn ngữ',
		// 'Khiếm thính hoặc suy giảm các giác quan khác',
		// 'Rối loạn thần kinh',
		// 'Ngôn ngữ thoái triển',
		// 'Rối loạn phát âm',
		// 'Chứng khó đọc',
		// 'Câm chọn lọc',
		// 'Khuyết tật cấu trúc (sứt môi, chẻ vòm)',
		// 'Rối loạn lưu loát ở trẻ',
		// 'Rối loạn giao tiếp xã hội',
		// 'Rối loạn lo âu xã hội',
		// 'Rối loạn phổ tự kỷ',
		// 'Hội chứng Rett',
		// 'Rối loạn vận động rập khuôn/ định hình',
		// 'OCD và các rối loạn liên quan',
		// 'Rối loạn Tic',
		// 'Rối loạn tic nhất thời',
		// 'Rối loạn tic vận động và lời nói mạn tính',
		// 'Tâm thần phân liệt',
		// 'Rối loạn tăng động giảm chú ý',
		// 'Rối loạn tăng động giảm chú ý kết hợp',
		// 'Giảm chú ý biểu hiện ưu thế',
		// 'Tăng động biểu hiện ưu thế',
		// 'Rối loạn thách thức chống đối',
		// 'Rối loạn bùng phát gián đoạn',
		// 'Rối loạn học tập biệt định',
		// 'Rối loạn phản ứng gắn bó',
		// 'Rối loạn lo âu',
		// 'Rối loạn trầm cảm',
		// 'Rối loạn lưỡng cực',
		// 'Rối loạn điều hòa khí sắc',
		// 'Rối loạn nhân cách',
		// 'Thuốc gây ra rối loạn tăng động giảm chú ý',
		// 'Rối loạn nhận thức thần kinh',
		// 'Rối loạn học tập biệt định',
		// 'Học tập khó khăn do rối loạn giác quan và thần kinh',
		// 'Rối loạn phối hợp phát triển',
		// 'Rối loạn điều hòa phát triển',
		// 'Theo dõi nguy cơ rối loạn phổ tự kỷ',
		// 'Theo dõi nguy cơ tăng động giảm chú ý',
		// 'Chậm nói',
		// 'Rối loạn âm lời nói',
		// 'Chậm phát triển',
		// 'Chậm vận động',
		// 'Chậm phát triển tâm thần vận động',
		// 'Tổn thương não',
		// 'Tăng trương lực cơ',
		// 'Giảm trương lực cơ',
		// 'Khiếm thính',
		// 'Hở hàm ếch',
		// 'Động kinh',
		// 'Khó khăn học tập',
		// 'Khó khăn đọc viết',
		// 'Chứng khó đọc',
		// 'Táo bón',
		// 'Rối loạn giấc ngủ',
		// 'Khó khăn ăn uống'
	];

	error = {
		name: [],
		assessment_date: [],
	};

	get errorName() {
		if (this.error.name) {
			return this.error.name.join('<br/>');
		} else {
			return '';
		}
	}

	get errorCreatedTime() {
		if (this.error.assessment_date) {
			return this.error.assessment_date.join('<br/>');
		} else {
			return '';
		}
	}

	get id() {
		return Number(this.$route.params.id);
	}

	@Watch('resultData', { deep: true, immediate: true })
	onResultData(value) {
		if (value) {
			this.assessment_conclusions = value?.assessment_conclusions
				? [...value?.assessment_conclusions]
				: [];
			this.development_ages = value?.development_ages
				? [...value?.development_ages]
				: [];
			this.prescription = value?.prescription
				? [...value?.prescription]
				: [];
			Object.assign(this.result, value);
		}
	}

	@Watch('refresh', { immediate: true })
	onRefresh(value) {
		if (value) {
			this.development_ages = [''];
			this.prescription = [''];
			this.assessment_conclusions = [''];
			this.result = {
				id: '',
				name: '',
				assessment_date: new Date(),
				note: '',
			};
			this.resultData = {
				id: '',
				name: '',
				assessment_date: new Date(),
				note: '',
			};
			this.tab = 0;
			this._isData = false;
		}
	}

	removeAge(index) {
		this.development_ages.splice(index, 1);
	}

	addAge() {
		this.development_ages.push('');
	}

	removePescription(index) {
		this.prescription.splice(index, 1);
	}

	addPescription() {
		this.prescription.push('');
	}

	removeConclusions(index) {
		this.assessment_conclusions.splice(index, 1);
	}

	addConclusions() {
		this.assessment_conclusions.push('');
	}

	toPreview() {
		this.$emit('toPreview', {
			...this.result,
			prescription: this.prescription?.filter((p) => !!p),
			development_ages: this.development_ages?.filter((d) => !!d),
			assessment_conclusions: this.assessment_conclusions?.filter(
				(a) => !!a
			),
		});
	}

	mounted() {
		this.$nextTick(() => {
			localStorage.removeItem('result');
		});
	}
}
</script>

<style lang="scss" scoped>
@import '../../../../../assets/styles/responsive';

.side-over {
	width: 100vw;
	padding-left: 0;

	@include media-breakpoint-up(md) {
		width: 600px;
	}
}

.sideover-content {
	height: calc(100vh - 300px);
	overflow-y: auto;
	padding-bottom: 100px;
}
</style>
