<script setup>
import { defineProps, computed } from 'vue';
import { useStore } from 'vuex';
import TooltipGoalDetail from '@/components/elements/tooltipGoalDetail/TooltipGoalDetail';

const props = defineProps(['data', 'id']);
const store = useStore();
const org = computed(() => store.state.profile.current_org);

const getStatus = (state) => {
	switch (state) {
		case 'MT hoàn thành':
			return `<div class=" flex items-center">
                  <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path fill-rule="evenodd" clip-rule="evenodd" d="M16 8.5C16 12.9183 12.4183 16.5 8 16.5C3.58172 16.5 0 12.9183 0 8.5C0 4.08172 3.58172 0.5 8 0.5C12.4183 0.5 16 4.08172 16 8.5ZM12.0303 5.46967C11.7374 5.17678 11.2625 5.17678 10.9697 5.46967C10.9626 5.47674 10.9559 5.48424 10.9498 5.4921L7.47739 9.91674L5.38387 7.82322C5.09097 7.53033 4.6161 7.53033 4.32321 7.82322C4.03031 8.11612 4.03031 8.59099 4.32321 8.88388L6.96965 11.5303C7.26255 11.8232 7.73742 11.8232 8.03031 11.5303C8.03684 11.5238 8.04299 11.5169 8.04875 11.5097L12.041 6.51947C12.3232 6.22582 12.3196 5.75897 12.0303 5.46967Z" fill="#2AA797"/>
                  </svg>
                  <span class="ml-3 text-neutral-700"> Hoàn thành </span>
                  </div>`;
		case 'MT mới':
			return `<div class=" flex items-center">
                  <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path fill-rule="evenodd" clip-rule="evenodd" d="M16 8.5C16 12.9183 12.4183 16.5 8 16.5C3.58172 16.5 0 12.9183 0 8.5C0 4.08172 3.58172 0.5 8 0.5C12.4183 0.5 16 4.08172 16 8.5ZM8.5 5C8.5 4.72386 8.27614 4.5 8 4.5C7.72386 4.5 7.5 4.72386 7.5 5V8H4.5C4.22386 8 4 8.22386 4 8.5C4 8.77614 4.22386 9 4.5 9H7.5V12C7.5 12.2761 7.72386 12.5 8 12.5C8.27614 12.5 8.5 12.2761 8.5 12V9H11.5C11.7761 9 12 8.77614 12 8.5C12 8.22386 11.7761 8 11.5 8H8.5V5Z" fill="#E5A42B"/>
                  </svg>
                  <span class="ml-3 text-neutral-700"> ${state} </span>
                  </div>`;
		case 'MT tạm dừng':
			return `<div class=" flex items-center">
                  <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path fill-rule="evenodd" clip-rule="evenodd" d="M8 16.5C12.4183 16.5 16 12.9183 16 8.5C16 4.08172 12.4183 0.5 8 0.5C3.58172 0.5 0 4.08172 0 8.5C0 12.9183 3.58172 16.5 8 16.5ZM12.1667 8.49677C12.1653 8.13006 11.942 7.83333 11.6667 7.83333H4.66667C4.39052 7.83333 4.16667 8.13181 4.16667 8.5C4.16667 8.86819 4.39052 9.16667 4.66667 9.16667H11.6667C11.942 9.16667 12.1653 8.86994 12.1667 8.50323C12.1667 8.50216 12.1667 8.50108 12.1667 8.5C12.1667 8.49892 12.1667 8.49784 12.1667 8.49677Z" fill="#E37056"/>
                  </svg>
                  <span class="ml-3 text-neutral-700"> Tạm dừng </span>
                  </div>`;
		case 'MT lặp lại':
			return `<div class=" flex items-center">
                  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M11.5338 7H15.4662C15.6782 7 15.794 7.24721 15.6583 7.41005L13.6921 9.76953C13.5921 9.88947 13.4079 9.88947 13.3079 9.76953L11.3417 7.41005C11.206 7.24721 11.3218 7 11.5338 7Z" fill="#94A3B8"/>
                  <path d="M0.533761 9H4.46624C4.6782 9 4.79399 8.75279 4.6583 8.58995L2.69206 6.23047C2.59211 6.11053 2.40789 6.11053 2.30795 6.23047L0.341706 8.58995C0.206013 8.75279 0.321802 9 0.533761 9Z" fill="#94A3B8"/>
                  <path fill-rule="evenodd" clip-rule="evenodd" d="M8 3C6.44751 3 5.06057 3.70697 4.14269 4.81839C3.96685 5.03131 3.65169 5.06137 3.43877 4.88552C3.22586 4.70968 3.1958 4.39453 3.37164 4.18161C4.47134 2.85003 6.13662 2 8 2C10.9417 2 13.388 4.1165 13.9011 6.90967C13.9066 6.9397 13.9119 6.96981 13.917 7H12.9001C12.4371 4.71778 10.4186 3 8 3ZM3.09992 9C3.56293 11.2822 5.58137 13 8 13C9.55249 13 10.9394 12.293 11.8573 11.1816C12.0332 10.9687 12.3483 10.9386 12.5612 11.1145C12.7741 11.2903 12.8042 11.6055 12.6284 11.8184C11.5287 13.15 9.86339 14 8 14C5.0583 14 2.61198 11.8835 2.09892 9.09033C2.0934 9.0603 2.08811 9.03019 2.08304 9H3.09992Z" fill="#94A3B8"/>
                  </svg>
                  <span class="ml-3 text-neutral-700"> MT lặp lại </span>
                  </div>`;
		default:
			return state;
	}
};
</script>
<template>
	<table class="min-w-full divide-y text-neutral-200 mb-5">
		<thead class="bg-neutral-100 h-16">
			<tr>
				<th
					scope="col"
					class="
						px-6
						py-3
						text-left text-xs
						font-semibold
						text-neutral-600
						uppercase
						tracking-wider
						w-8
						whitespace-nowrap
					"
				></th>
				<th
					scope="col"
					class="
						px-6
						py-3
						text-left text-xs
						font-semibold
						text-neutral-600
						uppercase
						tracking-wider
						w-16
						whitespace-nowrap
					"
				>
					STT
				</th>
				<th
					scope="col"
					class="
						px-6
						py-3
						text-left text-xs
						font-semibold
						text-neutral-600
						uppercase
						tracking-wider
						whitespace-nowrap
					"
				>
					MỤC TIÊU CỤ THỂ
				</th>
				<th
					scope="col"
					class="
						px-6
						py-3
						text-left text-xs
						font-semibold
						text-neutral-600
						uppercase
						tracking-wider
						whitespace-nowrap
					"
				>
					KẾ HOẠCH
				</th>
				<th
					scope="col"
					class="
						px-6
						py-3
						text-left text-xs
						font-semibold
						text-neutral-600
						uppercase
						tracking-wider
						whitespace-nowrap
					"
				>
					TRẠNG THÁI
				</th>
			</tr>
		</thead>
		<tbody style="border-top: none">
			<tr
				v-for="(g, i) in props.data"
				:key="g.goal_id || g.id"
				@click.stop="
					$router.push(`/goal/${props.id}/${g.goal_id || g.id}`)
				"
				:class="i % 2 === 0 ? 'bg-white' : 'bg-neutral-50'"
				class="text-neutral-700 hover:bg-orange-50 cursor-pointer"
			>
				<td class="px-6 py-4 w-8 divTableCell">
					<div class="w-4">
						<img
							class="w-full"
							src="@/assets/images/icons/child-goal.png"
							alt=""
						/>
					</div>
				</td>
				<td class="px-6 py-4 whitespace-nowrap text-neutral-700">
					<div class="ml-2">
						{{ i + 1 }}
					</div>
				</td>
				<td class="px-6 py-4 text-neutral-700">
					{{ g?.skill?.name }}
				</td>
				<td class="px-6 py-4 text-neutral-700">
					<div class="flex items-center">
						<div
							class="mr-2 hover:text-yellow-700 hover:underline"
							v-html="g.name"
						></div>
						<TooltipGoalDetail
							:goal-id="g.goal_id || g.id"
							:student-id="id"
						/>
					</div>
					<div class="flex gap-2 flex-wrap" style="max-width: 360px">
						<div
							class="
								mt-1
								py-0.5
								px-2.5
								rounded-full
								bg-neutral-200
								text-neutral-700
								h-fit
								w-fit
								font-semibold
								shadow-input
								whitespace-nowrap
							"
							style="font-size: 10px"
							v-if="g?.source"
						>
							{{ g?.source }}
						</div>
						<div
							class="
								mt-1
								py-0.5
								px-2.5
								rounded-full
								bg-neutral-200
								text-neutral-700
								h-fit
								w-fit
								font-semibold
								shadow-input
								whitespace-nowrap
							"
							style="font-size: 10px"
							v-else
						>
							{{ $filters.sourceMap(g?.state, g.editable) }}
						</div>
						<template
							v-if="
								g?.assistance_levels &&
								org.enable_goal_assistance_level
							"
							class="flex gap-2"
						>
							<div v-for="ass in g?.assistance_levels" :key="ass">
								<div
									class="
										mt-1
										py-0.5
										px-2.5
										rounded-full
										bg-pink-100
										whitespace-nowrap
										text-neutral-700
										h-fit
										w-fit
										font-semibold
										shadow-input
									"
									style="font-size: 10px"
								>
									{{ ass }}
								</div>
							</div>
						</template>
						<div
							class="
								mt-1
								py-0.5
								px-2.5
								rounded-full
								bg-yellow-tag
								text-neutral-700
								h-fit
								w-fit
								font-semibold
								shadow-input
								whitespace-nowrap
							"
							style="font-size: 10px"
							v-if="g?.age"
						>
							{{ g?.age }}
						</div>
					</div>
				</td>
				<td class="px-6 py-4 whitespace-nowrap text-neutral-700">
					<div class="ml-2">{{ getStatus(g.display_source) }}</div>
				</td>
			</tr>
		</tbody>
	</table>
</template>
