
import { computed, defineComponent, ref } from 'vue';
import { useRoute } from 'vue-router';
import { _MODAL_TYPE, _ROUTER_NAME } from '@/enums';
import router from '@/router';
import { useStore } from 'vuex';
import Loading from '@/components/common/Loading.vue';
import { Menu, MenuButton, MenuItem, MenuItems } from '@headlessui/vue';
import { $api } from '@/services';
import GoalListFull from '@/components/elements/GoalListFull.vue';
import GroupDetailFull from '@/components/modules/groups/GroupDetailfull.vue';

export default defineComponent({
	name: 'ReportTarget',
	components: {
		GoalListFull,
		GroupDetailFull,
		Loading,
		Menu,
		MenuItem,
		MenuItems,
		MenuButton,
	},
	setup() {
		const route = useRoute();
		const store = useStore();
		const groups = ref([]);
		const skill_count = ref(0);
		const goal_count = ref(0);
		const total_group_count = ref(0);
		const isLoading = ref(true);

		const id = computed(() =>
			route.params?.id ? route.params?.id + '' : ''
		);

		const is_old_data = ref(route.query.is_old_data === 'true');

		async function listGroup() {
			try {
				const {
					data: { data, stats },
				} = await $api.goal.getListGroupWithDetailInLibs(id.value);
				groups.value = data || [];
				if (stats) {
					skill_count.value = stats?.total_skill_count || 0;
					goal_count.value = stats?.total_goal_count || 0;
					total_group_count.value = stats?.total_group_count || 0;
				}
			} catch (err) {
				await store.dispatch('setAlertMessage', {
					message: 'Đã có lỗi xảy ra. Vui lòng thử lại',
					type: 'danger',
				});
			} finally {
				isLoading.value = false;
			}
		}

		listGroup();

		function redirectCreatePage() {
			router.push({
				name: _ROUTER_NAME.PLANS_REPORT_DETAIL__GOAL_CREATE_LIB__CREATE,
				params: {
					id: id.value,
				},
			});
		}

		return {
			id,
			groups,
			goal_count,
			skill_count,
			total_group_count,
			modalTypes: computed(() => _MODAL_TYPE),
			stop,
			redirectCreatePage,
			listGroup,
			org: computed(() => store.state.profile.current_org),
			isLoading,
			is_old_data,
		};
	},
});
