<template>
	<div
		class="_invite-employee _modal bg-white scroll-bar--none"
		style="max-height: 90vh; overflow-y: auto"
	>
		<div
			class="
				_modal__content
				md-down:px-4
				px-12
				py-6
				flex flex-col
				items-center
				justify-center
			"
		>
			<div>
				<img
					src="@/assets/images/illustrations/update.png"
					alt="Luca Education"
					title="Luca Education"
					height="150"
					width="150"
				/>
			</div>
			<div
				class="
					_modal__content__title
					text-neutral-700
					font-semibold
					text-2xl
				"
			>
				Chỉnh sửa mục tiêu
			</div>
			<div class="text-center text-neutral-500 mt-2">
				Bạn hãy điền thông tin dưới đây để thêm mục tiêu nhé
			</div>
			<div class="w-full mt-5">
				<div class="select-tabs mb-6">
					<Multiselect
						deselect-label="Đã chọn"
						track-by="name"
						:options="
							skillsData?.map((s) => {
								return {
									nameSearch: toEnglish(s?.name),
									...s,
								};
							})
						"
						v-model="skillSelected"
						:searchable="true"
						label="nameSearch"
						:allow-empty="false"
						selectLabel=""
						placeholder="Chọn lĩnh vực"
					>
						<template #noResult> Không tìm thấy kết quả </template>
						<template v-slot:option="props">
							<div class="flex flex-nowrap items-center">
								<div>
									{{ props.option.name }}
								</div>
							</div>
						</template>
						<template v-slot:singleLabel="{ option }">
							<div
								class="
									flex flex-nowrap
									items-center
									font-medium
								"
							>
								<div>{{ option.name }}</div>
							</div>
						</template>
					</Multiselect>
				</div>
				<div class="mb-4">
					<textarea
						placeholder="Nội dung mục tiêu"
						v-model="_data.name"
						:class="_error ? 'text-error' : ''"
					>
					</textarea>
					<div class="text-sienna-600 text-sm" v-if="_error">
						{{ _error }}
					</div>
				</div>
				<div
					class="font-semibold text-neutral-600 mb-2"
					v-if="enable_goal_assistance_level"
				>
					Mức độ hỗ trợ*
				</div>
				<div
					class="select-tabs mb-6 w-full mr-8"
					v-if="enable_goal_assistance_level"
				>
					<div
						v-for="(item, ass) in assistanceSelecteds"
						:key="ass"
						class="mb-4"
					>
						<Multiselect
							v-if="assistance_levels.length > 0"
							v-model="assistanceSelecteds[ass]"
							track-by="name"
							:options="
								assistance_levels
									?.filter(
										(a) =>
											!assistanceSelecteds.find(
												(s) => s.order === a.order
											)
									)
									?.map((s) => {
										return {
											nameSearch: toEnglish(s?.name),
											...s,
										};
									})
							"
							:searchable="true"
							label="nameSearch"
							selectLabel=""
							placeholder="Chọn mức độ hỗ trợ"
							selectedLabel=""
							deselectLabel="Ấn đễ bỏ chọn"
							:class="errorAssistanceLevels ? 'text-error' : ''"
						>
							<template #noResult>
								Không tìm thấy kết quả
							</template>
							<template v-slot:option="props">
								<div class="flex flex-nowrap items-center">
									<div>
										{{ props.option.name }}
									</div>
								</div>
							</template>
							<template v-slot:singleLabel="{ option }">
								<div
									class="
										flex flex-nowrap
										items-center
										font-medium
									"
								>
									<div>{{ option.name }}</div>
								</div>
							</template>
						</Multiselect>
						<div
							class="text-sienna-600 text-sm mt-2"
							v-if="errorAssistanceLevels[ass]"
						>
							{{ errorAssistanceLevels[ass] }}
						</div>
						<div
							@click="addAssistanceLevel"
							class="
								font-semibold
								text-yellow-700
								mb-2
								text-sm text-right
								cursor-pointer
							"
						>
							Thêm Mức độ hỗ trợ
						</div>
					</div>
				</div>
				<div class="mb-4" v-if="enable_goal_reason">
					<textarea
						placeholder="cơ sở hợp lý của mục tiêu"
						v-model="reasonInput"
						:class="errorReason ? 'text-error' : ''"
					>
					</textarea>
					<div class="text-sienna-600 text-sm" v-if="errorReason">
						{{ errorReason }}
					</div>
				</div>
				<div class="mb-4" v-if="enable_goal_strategy">
					<textarea
						placeholder="Chiến lược"
						v-model="strategyInput"
						:class="errorStrategy ? 'text-error' : ''"
					>
					</textarea>
					<div class="text-sienna-600 text-sm" v-if="errorStrategy">
						{{ errorStrategy }}
					</div>
				</div>
				<div class="mb-4" v-if="enable_goal_process">
					<textarea
						placeholder="Quy trình thực hiện"
						v-model="processInput"
						:class="errorProcess ? 'text-error' : ''"
					>
					</textarea>
					<div class="text-sienna-600 text-sm" v-if="errorProcess">
						{{ errorProcess }}
					</div>
				</div>
			</div>
		</div>
		<div
			class="
				_modal__footer
				px-12
				py-6
				bg-neutral-50
				flex flex-nowrap
				items-center
			"
		>
			<button
				type="button"
				class="
					button-p
					text-center
					mr-3
					bg-none
					shadow-none
					text-neutral-500
					hover:text-neutral-700
				"
				@click="$emit('close')"
			>
				Đóng
			</button>
			<button
				type="button"
				class="button-p text-center whitespace-nowrap"
				@click="submit"
			>
				Lưu mục tiêu
			</button>
		</div>
	</div>
</template>

<script>
import { Options, Vue } from 'vue-class-component';
import Multiselect from '@suadelabs/vue3-multiselect';
import { PropSync, Watch } from 'vue-property-decorator';
import { toEnglish } from '@/helper/toEnglish';
import { $api } from '@/services';

@Options({
	name: 'EditGoal',
	components: { Multiselect },
})
export default class EditGoal extends Vue {
	@PropSync('skills')
	skillsData;
	@PropSync('data')
	_data;
	@PropSync('error')
	_error;

	assistance_levels = [];

	processInput = '';
	reasonInput = '';
	strategyInput = '';
	skillSelected = null;
	assistanceSelected = null;
	enable_goal_assistance_level = false;
	enable_goal_process = false;
	enable_goal_reason = false;
	enable_goal_strategy = false;
	assistanceSelecteds = [''];

	//
	errorName = '';
	errorStrategy = '';
	errorProcess = '';
	errorReason = '';
	errorAssistanceLevels = '';

	get org() {
		return this.$store.state.profile.current_org;
	}

	mounted() {
		if (this.org) {
			this.enable_goal_assistance_level =
				this.org.enable_goal_assistance_level;
			this.enable_goal_process = this.org.enable_goal_process;
			this.enable_goal_reason = this.org.enable_goal_reason;
			this.enable_goal_strategy = this.org.enable_goal_strategy;
		}
		this.fetchAssistanceLevel()
	}

	@Watch('_data', { deep: true, immediate: true })
	onChange(value) {
		if (value?.assistance_levels) {
			this.assistanceSelecteds = value.assistance_levels.length
				? this.assistance_levels.filter((item) => {
						return value.assistance_levels.find(
							(a) => a === item.name
						);
						// eslint-disable-next-line no-mixed-spaces-and-tabs
				  })
				: [''];
		} else {
			this.assistanceSelected = null;
		}
		if (value.process) {
			this.processInput = value.process;
		}
		if (value.reason) {
			this.reasonInput = value.reason;
		}
		if (value.strategy) {
			this.strategyInput = value.strategy;
		}
		if (value.skill_id) {
			this.skillSelected = this.skillsData.find((item) => {
				return item.id === value.skill_id;
			});
		} else {
			this.skillSelected = null;
		}
	}

	submit() {
		let data = {
			id: this._data.id,
			// name: this._data.name.replace(/\n/g, '<br />'),
			name: this._data.name,
			skill_id: this.skillSelected.id,
			assistance_level: this.assistanceSelecteds
				?.map((i) => i.order)
				.filter((i) => !!i),
			assistance_levels: this.assistanceSelecteds
				?.map((i) => i.order)
				.filter((i) => !!i),
			process: this.processInput,
			reason: this.reasonInput,
			strategy: this.strategyInput,
		};
		this.$emit('submit', data);
	}

	addAssistanceLevel() {
		this.assistanceSelecteds.push('');
	}

	toEnglish(string = '') {
		return toEnglish(string);
	}

	async fetchAssistanceLevel() {
		try {
			const res = await $api.org.staffFetchAssistanceLevel();
			if (res?.data) {
				this.assistance_levels = res?.data?.data;
			}
		} catch (e) {
			console.log(e);
		}
	}
}
</script>
