<template>
	<div class="grid grid-cols-2 min-h-screen">
		<div class="__setup-center px-12 md-down:px-4 pb-30">
			<div
				class="
					__setup-center__header
					pt-10
					pb-4
					flex
					justify-between
					gap-8
					items-center
					lg:flex-nowrap
					flex-wrap
				"
			>
				<div
					class="
						__record__title
						text-neutral-500
						font-semibold
						whitespace-nowrap
						flex flex-nowrap
						items-center
						text-4xl
						cursor-pointer
					"
					@click="$router.back()"
				>
					<svg
						class="mr-2"
						width="28"
						height="28"
						viewBox="0 0 28 28"
						fill="none"
						xmlns="http://www.w3.org/2000/svg"
					>
						<path
							d="M17.5 7L10.5 14L17.5 21"
							stroke="#94A3B8"
							stroke-width="2"
							stroke-linecap="round"
							stroke-linejoin="round"
						/>
					</svg>
					Thêm học sinh
				</div>
				<div
					class="search-input w-80 lg-down:ml-auto"
					style="min-width: 120px"
				>
					<div class="input-group input--xl">
						<input
							type="text"
							placeholder="Tìm theo học sinh"
							class="bg-white input-with-icon"
							v-model="searchQuery"
							@keyup="searching"
							@keypress.enter="search"
						/>
						<div class="input-icon absolute top-1/2">
							<DaIcon
								name="das--search"
								class="h-4 w-4 text-neutral-400"
							/>
						</div>
					</div>
				</div>
			</div>
			<div>
				<div class="mt-6">
					<div
						class="font-semibold text-neutral-500"
						v-if="students.length"
					>
						Chọn học sinh để thêm vào danh sách
					</div>
					<div
						class="shadow-sm sm:rounded-2xl mt-4"
						v-if="students.length"
					>
						<div class="__record__table overflow-x-auto scroll-bar">
							<div
								class="
									overflow-hidden
									sm:rounded-t-2xl
									w-fit
									min-w-full
								"
							>
								<table
									class="min-w-full divide-y text-neutral-200"
								>
									<thead class="bg-neutral-100 h-16">
										<tr>
											<th
												scope="col"
												class="
													px-6
													py-3
													text-left text-xs
													font-semibold
													text-neutral-600
													uppercase
													tracking-wider
													w-16
													whitespace-nowrap
												"
											>
												STT
											</th>
											<th
												scope="col"
												class="
													px-6
													py-3
													text-left text-xs
													font-semibold
													text-neutral-600
													uppercase
													tracking-wider
													whitespace-nowrap
												"
											>
												Tên học sinh
											</th>
											<th class="w-8">
												<Checkbox
													:value="checkAll"
													@click.stop="addAllStudent"
												/>
											</th>
										</tr>
									</thead>
									<tbody>
										<tr
											@click="
												$router.push(
													isAdmin
														? `/record/student/${person.id}`
														: `/record/student/${person.id}/info`
												)
											"
											v-for="(
												person, index
											) in this.students.filter(
												(s) =>
													!this.checkBoxSelected.includes(
														s.id
													)
											)"
											:key="person.id"
											:class="
												index % 2 === 0
													? 'bg-white'
													: 'bg-neutral-50'
											"
											class="
												text-neutral-700
												hover:bg-orange-50
												cursor-pointer
											"
										>
											<td>
												<div
													class="
														px-6
														py-4
														text-neutral-700
													"
												>
													{{ index + 1 }}
												</div>
											</td>
											<td
												class="
													px-6
													py-4
													whitespace-nowrap
													text-neutral-700
												"
												style="width: 250px"
											>
												<div class="flex items-center">
													<div>
														<img
															class="
																shadow-sm
																inline-block
																h-6
																w-6
																rounded-full
															"
															:src="
																person
																	?.profile_photo
																	?.w200
															"
															alt=""
															v-if="
																person
																	?.profile_photo
																	?.w200
															"
														/>
														<span
															v-else
															class="
																inline-flex
																items-center
																justify-center
																h-6
																w-6
																rounded-full
															"
															:style="{
																background:
																	person
																		?.profile_photo
																		?.default
																		?.color,
															}"
														>
															<span
																class="
																	font-medium
																	leading-none
																	text-white
																	text-xs
																"
																>{{
																	person
																		?.profile_photo
																		?.default
																		?.initial
																}}</span
															>
														</span>
													</div>
													<div
														class="
															ml-4
															text-orange-600
															font-semibold
															truncate
														"
													>
														{{ person.name }}
													</div>
												</div>
											</td>
											<td>
												<Checkbox
													:value="
														checkBoxSelected.find(
															(id) =>
																id === person.id
														)
													"
													@click.stop="
														checkChanged(person.id)
													"
												/>
											</td>
										</tr>
									</tbody>
								</table>
							</div>
						</div>
					</div>
					<div
						v-else
						class="
							w-full
							h-full
							mt-20
							flex
							items-center
							justify-center
							flex-col
						"
					>
						<img
							src="@/assets/images/illustrations/not_found.png"
							alt=""
							height="250"
							width="250"
						/>
						<div
							class="text-neutral-500 text-lg text-center"
							style="max-width: 700px"
						>
							Rất tiếc, chúng tôi không thể tìm thấy kết quả cho
							tìm kiếm của bạn.
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="bg-white p-12">
			<div class="font-semibold text-neutral-600">
				Danh sách học sinh đã chọn
			</div>
			<div class="text-neutral-400 mt-1">
				Danh sách học sinh đã chọn sẽ được thêm vào danh sách đánh giá
			</div>
			<div class="flex items-center gap-5 mt-6">
				<div
					class="
						flex
						items-center
						justify-between
						gap-2
						py-4
						px-6
						shadow-sm
						w-full
						rounded-xl
					"
				>
					<div
						class="font-normal"
						style="color: #65758b; font-size: 14px"
					>
						Học sinh
					</div>
					<div class="text-4xl font-bold" style="color: #48566a">
						{{ studentsInClass?.length ?? '0' }}
					</div>
				</div>
			</div>
			<div>
				<div class="flex flex-col">
					<div
						class="shadow-sm sm:rounded-2xl mt-6"
						v-if="allStudents.length"
					>
						<div class="__record__table overflow-x-auto scroll-bar">
							<div
								class="
									overflow-hidden
									sm:rounded-t-2xl
									w-fit
									min-w-full
								"
							>
								<table
									class="min-w-full divide-y text-neutral-200"
								>
									<thead class="bg-neutral-100 h-16">
										<tr>
											<th
												scope="col"
												class="
													px-6
													py-3
													text-left text-xs
													font-semibold
													text-neutral-600
													uppercase
													tracking-wider
													w-16
													whitespace-nowrap
												"
											>
												STT
											</th>
											<th
												scope="col"
												class="
													px-6
													py-3
													text-left text-xs
													font-semibold
													text-neutral-600
													uppercase
													tracking-wider
													whitespace-nowrap
												"
											>
												Tên học sinh
											</th>
											<th class="w-8">
												<Checkbox
													:value="unCheckAll"
													@click.stop="removeStudent"
												/>
											</th>
										</tr>
									</thead>
									<tbody>
										<tr
											@click="
												$router.push(
													isAdmin
														? `/record/student/${person.id}`
														: `/record/student/${person.id}/info`
												)
											"
											v-for="(
												person, index
											) in studentsInClass"
											:key="person.id"
											:class="
												index % 2 === 0
													? 'bg-white'
													: 'bg-neutral-50'
											"
											class="
												text-neutral-700
												hover:bg-orange-50
												cursor-pointer
											"
										>
											<td>
												<div
													class="
														px-6
														py-4
														text-neutral-700
													"
												>
													{{ index + 1 }}
												</div>
											</td>
											<td
												class="
													px-6
													py-4
													whitespace-nowrap
													text-neutral-700
												"
												style="width: 250px"
											>
												<div class="flex items-center">
													<div>
														<img
															class="
																shadow-sm
																inline-block
																h-6
																w-6
																rounded-full
															"
															:src="
																person
																	?.profile_photo
																	?.w200
															"
															alt=""
															v-if="
																person
																	?.profile_photo
																	?.w200
															"
														/>
														<span
															v-else
															class="
																inline-flex
																items-center
																justify-center
																h-6
																w-6
																rounded-full
															"
															:style="{
																background:
																	person
																		?.profile_photo
																		?.default
																		?.color,
															}"
														>
															<span
																class="
																	font-medium
																	leading-none
																	text-white
																	text-xs
																"
																>{{
																	person
																		?.profile_photo
																		?.default
																		?.initial
																}}</span
															>
														</span>
													</div>
													<div
														class="
															ml-4
															text-orange-600
															font-semibold
															whitespace-pre-wrap
															w-40
														"
													>
														{{ person.name }}
													</div>
												</div>
											</td>
											<td>
												<Checkbox
													:value="
														checkBoxSelected.find(
															(id) =>
																id === person.id
														)
													"
													@click.stop="
														checkChanged(person.id)
													"
												/>
											</td>
										</tr>
									</tbody>
								</table>
							</div>
						</div>
					</div>
				</div>
				<div class="mt-6 flex justify-end">
					<button
						type="button"
						class="button-p text-center w-32"
						@click="addStudents"
					>
						Xác nhận
					</button>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import DaIcon from '@/components/elements/daIcon/DaIcon';
import { $api } from '@/services';
import Checkbox from '@/components/elements/checkbox/Checkbox';
import { toEnglish } from '@/helper/toEnglish';
export default {
	name: 'GroupClassAddStudents',

	components: { Checkbox, DaIcon },

	data() {
		return {
			searchQuery: '',
			students: [],
			allStudents: [],
			allStudentsAdded: [],
			params: {},
			checkBoxSelected: [],
			typing: null,
			checkAll: false,
			unCheckAll: false,
		};
	},

	created() {
		this.fetchStudents().then(() =>
			this.fetchStudentInPeriodicAssessment()
		);
	},

	computed: {
		studentsInClass() {
			return this.allStudents.filter((s) =>
				this.checkBoxSelected.includes(s.id)
			);
		},

		isAdmin() {
			return this.$store.getters.isAdmin;
		},
	},
	watch: {
		studentsInClass: {
			handler: function (newVal) {
				console.log('newVal', newVal.length);
				console.log(
					'this.studentsInClass.length',
					this.studentsInClass.length
				);
				if (this.students.length == newVal.length) {
					this.checkAll = true;
				} else this.checkAll = false;
				if (newVal.length > 1) this.unCheckAll = true;
				else this.unCheckAll = false;
			},
			deep: true,
		},
	},
	methods: {
		async fetchStudents() {
			try {
				const {
					data: { data },
				} = await $api.org.getStudentAssessment();
				this.students = data.map((item) => {
					return {
						...item,
						nameSearch: toEnglish(item?.name),
					};
				});
				this.allStudents = [...data].map((item) => {
					return {
						...item,
						nameSearch: toEnglish(item?.name),
					};
				});
			} catch (err) {
				console.log(err);
			}
		},

		checkChanged(id) {
			const result = this.checkBoxSelected.find((item) => item === id);

			if (result) {
				this.checkBoxSelected = this.checkBoxSelected.filter((item) => {
					return item !== id;
				});
			} else {
				this.checkBoxSelected.push(id);
			}
			console.log('this.checkBoxSelected', this.checkBoxSelected);
			console.log(
				'this.studentsInClass.length',
				this.studentsInClass.length
			);
		},
		addAllStudent() {
			this.students.forEach((item) => {
				this.checkBoxSelected.push(item.id);
			});
		},
		removeStudent() {
			this.checkBoxSelected.length = 0;
		},
		async search() {
			this.students = this.allStudents.filter((s) => {
				return (
					this.formatTextSearch(s.nameSearch).includes(
						this.formatTextSearch(this.searchQuery)
					) ||
					this.formatTextSearch(s.name).includes(
						this.formatTextSearch(this.searchQuery)
					) ||
					this.formatTextSearch(s.unaccent_name).includes(
						this.formatTextSearch(this.searchQuery)
					)
				);
			});
		},

		formatTextSearch(text) {
			if (!text) {
				return '';
			}
			return text.toLowerCase();
		},

		searching() {
			clearInterval(this.typing);
			this.typing = setTimeout(() => {
				this.search();
			}, 1000);
		},

		async addStudents() {
			try {
				await $api.org.updateStudentPeriodicAssessment({
					student_ids: this.studentsInClass.map((s) => s.id),
				});
				await this.$store.dispatch('setAlertMessage', {
					message: 'Thêm học sinh vào đánh giá định kỳ thành công',
				});
				await this.$router.push(`/setup-center/periodic-assessment`);
			} catch (err) {
				const error =
					err?.response?.data?.errors ||
					err?.message ||
					'Đã có lỗi xảy ra. Vui lòng thử lại';
				await this.$store.dispatch('setAlertMessage', {
					message: error,
					type: 'danger',
				});
			}
		},

		async fetchStudentInPeriodicAssessment() {
			try {
				const {
					data: { data },
				} = await $api.org.getPeriodicAssessment();
				this.checkBoxSelected = [...data].map((s) => s.id);
			} catch (e) {
				console.log(e);
			}
		},
	},
};
</script>
