
import { Options, Vue } from 'vue-class-component';
import { PropSync, Watch } from 'vue-property-decorator';

@Options({
	name: 'CreateStudent',
})
export default class CreateStudent extends Vue {
	//----------------------- 🤍 Props 🤍 -----------------------//
	@PropSync('inProgress', { default: false, type: Boolean })
	inProgress!: boolean;
	@PropSync('error', { default: '', type: String })
	error!: string;
	//----------------------- 🤍 Data 🤍 -----------------------//

	student = {
		name: '',
		full_name: '',
		dob: '',
		state: 'pending',
	};

	errorMes = {
		name: '',
		full_name: '',
		dob: '',
		state: '',
		status: false,
	};

	@Watch('error')
	onErrorPropsChange(value: string) {
		this.errorMes['name'] = `Tên hiển thị '${this.student.name}' ${value}`;
	}

	get maxDate() {
		const date = new Date();
		const day = date.getDate();
		const month = date.getMonth();
		const year = date.getFullYear();
		return new Date(year, month, day - 1);
	}

	//----------------------- 🤍 Methods 🤍 -----------------------//
	submit() {
		this.errorMes = {
			name: '',
			full_name: '',
			dob: '',
			state: '',
			status: false,
		};
		if (this.student['full_name']?.length <= 0) {
			this.errorMes['full_name'] = 'Tên đầy đủ không được bỏ trống';
			this.errorMes.status = true;
		}
		if (this.student['name']?.length <= 0) {
			this.errorMes['name'] = 'Tên hiển thị không được bỏ trống';
			this.errorMes.status = true;
		}
		if (this.errorMes.status) {
			return;
		}
		this.$emit('submit', this.student);
	}
}
