<template>
	<div class="__student-detail pb-30">
		<div class="__goal-management-student-detail p-10 md-down:px-4">
			<div class="flex items-center justify-between">
				<div
					class="
						__breadcrumbs
						text-sm text-neutral-500
						font-semibold
						flex-nowrap flex
						items-center
					"
				>
					<router-link :to="{ name: _ROUTER_NAME.GROUP_CLASSES }">
						Danh sách lớp nhóm
					</router-link>
				</div>
			</div>
			<div class="__goal-management-student-detail__header pt-5">
				<div class="flex gap-6" v-if="groupClass">
					<UserGroup
						:data="groupClass?.students"
						placement="bottom-start"
						class="mt-2"
					/>
					<div>
						<div class="text-neutral-700 font-bold text-4xl">
							{{ groupClass?.name }}
						</div>
						<div class="uppercase text-green-500 font-semibold">
							{{ groupClass?.students?.length }} học sinh
						</div>
					</div>
				</div>
				<div class="mt-10">
					<div class="border-b border-neutral-200 md-down:hidden">
						<nav
							class="-mb-px flex space-x-8 tabs overflow-auto"
							aria-label="Tabs"
						>
							<router-link
								v-for="tab in tabs"
								:key="tab.value"
								:to="{
									name: tab.value,
								}"
								class="
									border-transparent
									text-neutral-500
									hover:text-yellow-700
									hover:border-yellow-500
									whitespace-nowrap
									py-3
									px-1
									border-b-2
									font-semibold
								"
							>
								{{ tab.label }}
							</router-link>
						</nav>
					</div>
					<div class="md:hidden">
						<div class="p-6 bg-white select-tabs">
							<Multiselect
								v-model="tab"
								deselect-label="Đã chọn"
								track-by="value"
								:options="tabs"
								:searchable="true"
								label="label"
								:allow-empty="false"
								selectLabel=""
								placeholder=""
							>
								<template #noResult>
									Không tìm thấy kết quả
								</template>
								<template v-slot:option="props">
									<div class="flex flex-nowrap items-center">
										<div>
											{{ props.option.label }}
										</div>
									</div>
								</template>
								<template v-slot:singleLabel="{ option }">
									<div
										class="
											flex flex-nowrap
											items-center
											font-medium
										"
									>
										<div>{{ option.label }}</div>
									</div>
								</template>
							</Multiselect>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div
			class="
				__goal-management-student-detail__overview
				px-10
				pb-24
				md-down:px-4
			"
		>
			<router-view />
		</div>
	</div>
</template>

<script>
import Multiselect from '@suadelabs/vue3-multiselect';
import { _ROUTER_NAME } from '@/enums';
import { $api } from '@/services';
import UserGroup from '@/components/elements/UserGroup.vue';

export default {
	name: 'StudentDetail',

	components: { UserGroup, Multiselect },

	data() {
		return {
			tab: {
				label: 'Kế hoạch hiện tại',
				value: _ROUTER_NAME.GROUP_CLASS_PLANS_DETAIL_REPORT,
			},
			groupClass: null,
			isLoading: false,
		};
	},

  watch: {
    tab: {
      deep: true,
      handler (value) {
        if (value) {
          this.$router.push({
            name: value.value,
            query: { ...this.$route.query }
          })
        }
      }
    },

    '$route': {
      immediate: true,
      deep: true,
      handler(value) {
        const name = value?.name;
        this.tab = this.tabs.find((item) => item.value === name);
      }
    }
  },

	computed: {
		_ROUTER_NAME() {
			return _ROUTER_NAME;
		},

		tabs() {
			return [
				{
					label: 'Kế hoạch hiện tại',
					value: _ROUTER_NAME.GROUP_CLASS_PLANS_DETAIL_REPORT,
				},
				{
					label: 'Kế hoạch dự kiến',
					value: _ROUTER_NAME.GROUP_CLASS_PLANS_DETAIL_REPORT_FUTURE,
				},
				{
					label: 'Lịch sử kế hoạch',
					value: _ROUTER_NAME.GROUP_CLASS_PLANS_DETAIL_REPORT_HISTORY,
				},
				{
					label: 'Ngân hàng mục tiêu',
					value: _ROUTER_NAME.GROUP_CLASS_PLANS_DETAIL_REPORT_BANK,
				},
				{
					label: 'Mục tiêu hoàn thành',
					value: _ROUTER_NAME.GROUP_CLASS_PLANS_DETAIL_GOALS_COMPLETED,
				},
				{
					label: 'Mục tiêu tạm dừng',
					value: _ROUTER_NAME.GROUP_CLASS_PLANS_DETAIL_GOALS_STOPPED,
				},
				/*				{
					label: 'Tất cả mục tiêu',
					value: _ROUTER_NAME.GROUP_CLASS_PLANS_DETAIL_GOALS_ALL,
				},*/
			];
		},

		id() {
			return this.$route.params?.id;
		},
	},

	created() {
		this.fetchGroupClassDetail();
	},

	methods: {
		async fetchGroupClassDetail() {
			try {
				const {
					data: { data },
				} = await $api.groupClasses.detail(this.id);
				this.groupClass = data;
			} catch (e) {
				console.log(e);
			}
		},
	},
};
</script>

<style scoped lang="scss">
.tabs {
	.router-link-exact-active {
		@apply text-yellow-700 border-yellow-500;
	}
}
</style>
