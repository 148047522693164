<template>
	<div :class="sideOverShow ? 'side-over--active' : ''">
		<div
			class="
				fixed
				h-screen
				w-screen
				top-0
				left-0
				bg-black
				opacity-20
				z-30
			"
			v-if="sideOverShow"
			@click="sideOverShow = false"
		></div>
		<SideOver
			:students="students"
			:staffs="staffs"
			@close="sideOverShow = false"
			@submit="filterSubmit"
		/>
	</div>
	<div
		class="
			md:hidden
			fixed
			w-screen
			bg-neutral-100
			z-50
			p-4
			py-12
			transform
			transition-all
			duration-500
		"
		style="height: 2000px"
		:class="{ 'translate-x-full': !studentSelected && !showStats }"
	>
		<div class="flex items-center">
			<svg
				@click="closeStats"
				width="28"
				height="28"
				viewBox="0 0 28 28"
				fill="none"
				xmlns="http://www.w3.org/2000/svg"
			>
				<path
					d="M17.5 7L10.5 14L17.5 21"
					stroke="#94A3B8"
					stroke-width="2"
					stroke-linecap="round"
					stroke-linejoin="round"
				/>
			</svg>

			<div class="ml-3 text-gray-500 font-semibold text-lg">
				<div v-if="date">
					{{ dateTitle }}
				</div>
				<div v-else>Chi tiết</div>
			</div>
		</div>
		<div class="mt-10">
			<div
				v-if="studentSelected && !showStats"
				class="
					w-full
					shadow-sm
					bg-yellow-50
					rounded-lg
					overflow-hidden
					mt-4
					p-4
					relative
				"
			>
				<div class="flex flex-nowrap gap-4">
					<div class="flex items-start">
						<div v-if="studentSelected.student_id" class="h-9 w-9">
							<img
								class="
									shadow-sm
									inline-block
									h-9
									w-9
									rounded-full
								"
								:src="
									getStudent(studentSelected.student_id)
										?.profile_photo?.w200
								"
								alt=""
								v-if="
									getStudent(studentSelected.student_id)
										?.profile_photo?.w200
								"
							/>
							<span
								v-else
								class="
									inline-flex
									items-center
									justify-center
									h-9
									w-9
									rounded-full
								"
								:style="{
									background: getStudent(
										studentSelected.student_id
									)?.profile_photo?.default?.color,
								}"
							>
								<span
									class="
										font-medium
										leading-none
										text-white text-xs
									"
									>{{
										getStudent(studentSelected.student_id)
											?.profile_photo?.default?.initial
									}}</span
								>
							</span>
						</div>
						<div v-else>
							<img
								class="
									shadow-sm
									inline-block
									h-9
									w-9
									rounded-full
								"
								:src="
									studentSelected.student?.profile_photo?.w200
								"
								alt=""
								v-if="
									studentSelected.student?.profile_photo?.w200
								"
							/>
							<span
								v-else
								class="
									inline-flex
									items-center
									justify-center
									h-9
									w-9
									rounded-full
								"
								:style="{
									background:
										studentSelected.student?.profile_photo
											?.default?.color,
								}"
							>
								<span
									class="
										font-medium
										leading-none
										text-white text-xs
									"
									>{{
										studentSelected.student?.profile_photo
											?.default?.initial
									}}</span
								>
							</span>
						</div>
					</div>
					<div class="w-full">
						<div
							class="
								text-orange-600
								font-semibold
								truncate
								text-lg
							"
							v-if="studentSelected.student_id"
						>
							{{ getStudent(studentSelected.student_id)?.name }}
						</div>
						<div
							class="
								text-orange-600
								font-semibold
								truncate
								text-lg
							"
							v-else
						>
							{{ studentSelected.student?.name }}
						</div>
						<div
							class="flex gap-2 border-b w-full py-4"
							v-if="
								studentSelected.display_start_slot &&
								studentSelected.display_end_slot
							"
						>
							<div>
								<svg
									width="16"
									height="16"
									viewBox="0 0 16 16"
									fill="none"
									xmlns="http://www.w3.org/2000/svg"
								>
									<path
										fill-rule="evenodd"
										clip-rule="evenodd"
										d="M16 8C16 12.4183 12.4183 16 8 16C3.58172 16 0 12.4183 0 8C0 3.58172 3.58172 0 8 0C12.4183 0 16 3.58172 16 8ZM8 3.5C8 3.22386 7.77614 3 7.5 3C7.22386 3 7 3.22386 7 3.5V9C7 9.17943 7.09614 9.3451 7.25193 9.43412L10.7519 11.4341C10.9917 11.5711 11.2971 11.4878 11.4341 11.2481C11.5711 11.0083 11.4878 10.7029 11.2481 10.5659L8 8.70984V3.5Z"
										fill="#6366F1"
									/>
								</svg>
							</div>
							<div>
								<div class="text-neutral-600 font-semibold">
									<span
										>{{
											studentSelected.display_start_slot
										}}
										-
										{{
											studentSelected.display_end_slot
										}}</span
									>
								</div>
								<div v-if="studentSelected.staff_ids">
									<div
										class="flex items-center mt-2"
										v-for="staff_id in studentSelected.staff_ids"
										:key="staff_id"
									>
										<div>
											<img
												class="
													shadow-sm
													inline-block
													h-9
													w-9
													rounded-full
												"
												:src="
													getStaff(staff_id)
														?.profile_photo?.w200
												"
												alt=""
												v-if="
													getStaff(staff_id)
														?.profile_photo?.w200
												"
											/>
											<span
												v-else
												class="
													inline-flex
													items-center
													justify-center
													h-9
													w-9
													rounded-full
												"
												:style="{
													background:
														getStaff(staff_id)
															?.profile_photo
															?.default?.color,
												}"
											>
												<span
													class="
														font-medium
														leading-none
														text-white text-xs
													"
													>{{
														getStaff(staff_id)
															?.profile_photo
															?.default?.initial
													}}</span
												>
											</span>
										</div>
										<div
											class="
												font-semibold
												text-blue-600
												ml-4
											"
										>
											{{ getStaff(staff_id).name }}
										</div>
									</div>
								</div>
							</div>
						</div>
						<div
							class="border-b w-full py-4"
							v-if="
								studentSelected.unscheduled_note ||
								studentSelected.leave_note ||
								studentSelected.staff_change_note ||
								studentSelected.slot_change_note
							"
						>
							<div class="font-semibold text-neutral-600">
								Ghi chú
							</div>
							<div class="text-neutral-600">
								<div v-if="studentSelected.unscheduled_note">
									<span class="font-semibold"
										>Phát sinh: </span
									>{{ studentSelected.unscheduled_note }}
								</div>
								<div v-if="studentSelected.leave_note">
									<span class="font-semibold">Báo nghỉ: </span
									>{{ studentSelected.leave_note }}
								</div>
								<div v-if="studentSelected.staff_change_note">
									<span class="font-semibold">Đổi TLV: </span
									>{{ studentSelected.staff_change_note }}
								</div>
								<div v-if="studentSelected.slot_change_note">
									<span class="font-semibold">Đổi giờ: </span>
									{{ studentSelected.slot_change_note }}
								</div>
							</div>
						</div>
						<div class="mt-4 flex items-center gap-4">
							<div>
								<template
									v-if="
										studentSelected &&
										studentSelected.state === 'cancelled'
									"
								>
									<div
										v-if="studentSelected.student_on_leave"
										class="
											rounded-full
											border border-sienna-400
											flex
											items-center
											justify-center
											text-sm text-sienna-600
											font-semibold
											w-fit
											px-3
											py-1
										"
									>
										HS nghỉ
									</div>
									<div
										v-else
										class="
											rounded-full
											border border-sienna-400
											flex
											items-center
											justify-center
											text-sm text-sienna-600
											font-semibold
											w-fit
											px-3
											py-1
										"
									>
										TLV nghỉ
									</div>
								</template>
								<div v-else>
									<div
										v-if="
											studentSelected &&
											studentSelected.state === 'created'
										"
										class="
											rounded-full
											flex
											items-center
											justify-center
											bg-green-100
											text-xs text-green-600
											font-semibold
											w-fit
											px-3
											py-1
										"
									>
										Đã học
									</div>
									<div
										v-if="
											studentSelected &&
											studentSelected.state === 'ongoing'
										"
										class="
											rounded-full
											flex
											items-center
											justify-center
											bg-blue-100
											text-xs text-blue-600
											font-semibold
											w-fit
											px-3
											py-1
										"
									>
										Đang học
									</div>
									<div
										v-if="
											studentSelected &&
											studentSelected.state !== 'past' &&
											studentSelected.state !==
												'cancelled' &&
											studentSelected.state !==
												'created' &&
											studentSelected.state !== 'ongoing'
										"
										class="
											rounded-full
											flex
											items-center
											justify-center
											bg-neutral-100
											text-xs text-neutral-600
											font-semibold
											w-fit
											px-3
											py-1
										"
									>
										Chưa học
									</div>
								</div>
							</div>
							<div>
								<div
									v-if="!studentSelected.scheduled"
									class="
										text-xs
										rounded-full
										border border-sienna-400
										flex
										items-center
										justify-center
										text-xs text-sienna-600
										font-semibold
										w-fit
										px-2.5
										py-0.5
									"
								>
									Phát sinh
								</div>
								<div
									v-else
									class="
										w-fit
										text-xs
										font-semibold
										text-neutral-600
										px-2.5
										py-0.5
										rounded-full
										border border-neutral-300
										flex
										items-center
										justify-center
									"
								>
									Cố định
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div
				v-if="showStats"
				class="
					w-full
					shadow-sm
					bg-white
					rounded-lg
					overflow-hidden
					mt-4
				"
			>
				<div class="font-semibold text-gray-600 p-4">
					Thống kê theo ngày
				</div>
				<div
					class="
						p-4
						flex
						items-center
						justify-between
						border-t border-yellow-50
					"
				>
					<div class="text-sm">Giờ can thiệp/ dự kiến</div>
					<div class="text-3xl font-semibold text-neutral-600">
						{{ stats.total_non_cancelled_hours }}/{{
							stats.total_hours
						}}
					</div>
				</div>
				<div
					class="
						p-4
						flex
						items-center
						justify-between
						border-t border-yellow-50
					"
				>
					<div class="text-sm">Giờ can thiệp phát sinh</div>
					<div class="text-3xl font-semibold text-neutral-600">
						{{ stats.total_unscheduled_hours }}
					</div>
				</div>
				<div
					class="
						p-4
						flex
						items-center
						justify-between
						border-t border-yellow-50
					"
				>
					<div class="text-sm">Giờ can thiệp báo nghỉ</div>
					<div class="text-3xl font-semibold text-neutral-600">
						{{ stats.total_cancelled_hours }}
					</div>
				</div>
				<div
					class="
						p-4
						flex
						items-center
						justify-between
						border-t border-yellow-50
					"
				>
					<div class="text-sm">H.S bán trú/ dự kiến</div>
					<div class="text-3xl font-semibold text-neutral-600">
						{{
							Number(
								stats.total_non_cancelled +
									stats.total_unscheduled
							)
						}}
						/
						{{ stats.total }}
					</div>
				</div>
			</div>
		</div>
	</div>
	<div v-if="isClassed" :class="isShow ? 'side-over--active' : ''">
		<div
			class="
				fixed
				h-screen
				w-screen
				top-0
				left-0
				bg-black
				opacity-20
				z-30
			"
			v-if="isShow"
			@click="isShow = false"
		></div>
		<CreateTimeSlot @close="isShow = false" @success="successCreated" />
	</div>
	<div v-else-if="isGroup" :class="isShow ? 'side-over--active' : ''">
		<div
			class="
				fixed
				h-screen
				w-screen
				top-0
				left-0
				bg-black
				opacity-20
				z-30
			"
			v-if="isShow"
			@click="isShow = false"
		></div>
		<CreateTimeSlotGroupClass
			@close="isShow = false"
			@success="successCreated"
		/>
	</div>
	<div v-else :class="isShow ? 'side-over--active' : ''">
		<div
			class="
				fixed
				h-screen
				w-screen
				top-0
				left-0
				bg-black
				opacity-20
				z-30
			"
			v-if="isShow"
			@click="isShow = false"
		></div>
		<CreateUnScheduleSession
			@close="isShow = false"
			@success="successCreated"
		/>
	</div>
	<div
		v-if="!isLoading"
		class="
			__record
			px-10
			pb-30
			md-down:px-4
			md:h-screen
			overflow-hidden
			w-full
		"
	>
		<div
			class="
				__record__header
				pt-10
				pb-10
				flex flex-nowrap
				justify-between
				items-center
				gap-4
			"
		>
			<div
				class="
					__record__title
					text-neutral-500
					font-semibold
					whitespace-nowrap
					flex flex-nowrap
					items-center
					text-4xl
					cursor-pointer
				"
			>
				<DaIcon name="das--calender" class="mr-3 h-6 w-6" />
				<span class="mt-1.5">Lịch ngày</span>
			</div>

			<Menu as="div" class="ml-3 relative">
				<div>
					<MenuButton
						class="
							rounded-full
							flex
							text-sm
							focus:outline-none
							focus:ring-2
							focus:ring-offset-2
							focus:ring-indigo-500
						"
					>
						<span class="sr-only">Open user menu</span>
						<svg
							width="20"
							height="20"
							viewBox="0 0 20 20"
							fill="none"
							xmlns="http://www.w3.org/2000/svg"
						>
							<path
								fill-rule="evenodd"
								clip-rule="evenodd"
								d="M11.875 16.25C11.875 17.2855 11.0355 18.125 10 18.125C8.96447 18.125 8.125 17.2855 8.125 16.25C8.125 15.2145 8.96447 14.375 10 14.375C11.0355 14.375 11.875 15.2145 11.875 16.25ZM11.875 10C11.875 11.0355 11.0355 11.875 10 11.875C8.96447 11.875 8.125 11.0355 8.125 10C8.125 8.96447 8.96447 8.125 10 8.125C11.0355 8.125 11.875 8.96447 11.875 10ZM11.875 3.75C11.875 4.78553 11.0355 5.625 10 5.625C8.96447 5.625 8.125 4.78553 8.125 3.75C8.125 2.71447 8.96447 1.875 10 1.875C11.0355 1.875 11.875 2.71447 11.875 3.75Z"
								fill="#94A3B8"
							/>
						</svg>
					</MenuButton>
				</div>
				<transition
					enter-active-class="transition ease-out duration-200"
					enter-from-class="transform opacity-0 scale-95"
					enter-to-class="transform opacity-100 scale-100"
					leave-active-class="transition ease-in duration-75"
					leave-from-class="transform opacity-100 scale-100"
					leave-to-class="transform opacity-0 scale-95"
				>
					<MenuItems
						class="
							origin-top-right
							absolute
							right-0
							mt-2
							w-80
							rounded-md
							py-1
							bg-white
							focus:outline-none
							z-10
							shadow-sm
							border border-yellow-100
						"
					>
						<MenuItem @click="isShow = true" v-slot="{ active }">
							<div
								class="
									p-2
									px-4
									bg-white
									z-10
									text-gray-600
									hover:bg-neutral-50
									flex
									items-center
									gap-4
									cursor-pointer
								"
								:class="active ? '' : ''"
							>
								<svg
									width="16"
									height="16"
									viewBox="0 0 16 16"
									fill="none"
									xmlns="http://www.w3.org/2000/svg"
								>
									<path
										fill-rule="evenodd"
										clip-rule="evenodd"
										d="M16 8C16 12.4183 12.4183 16 8 16C3.58172 16 0 12.4183 0 8C0 3.58172 3.58172 0 8 0C12.4183 0 16 3.58172 16 8ZM8.5 4.5C8.5 4.22386 8.27614 4 8 4C7.72386 4 7.5 4.22386 7.5 4.5V7.5H4.5C4.22386 7.5 4 7.72386 4 8C4 8.27614 4.22386 8.5 4.5 8.5H7.5V11.5C7.5 11.7761 7.72386 12 8 12C8.27614 12 8.5 11.7761 8.5 11.5V8.5H11.5C11.7761 8.5 12 8.27614 12 8C12 7.72386 11.7761 7.5 11.5 7.5H8.5V4.5Z"
										fill="#94A3B8"
									/>
								</svg>
								<span class="mt-0.5"
									>Tạo buổi học phát sinh</span
								>
							</div>
						</MenuItem>
						<MenuItem
							class="md:hidden"
							v-slot="{ active }"
							@click="showStats = true"
						>
							<div
								class="
									p-2
									px-4
									bg-white
									z-10
									text-gray-600
									hover:bg-neutral-50
									flex
									items-center
									gap-4
									cursor-pointer
								"
								:class="active ? '' : ''"
							>
								<svg
									width="16"
									height="16"
									viewBox="0 0 16 16"
									fill="none"
									xmlns="http://www.w3.org/2000/svg"
								>
									<path
										fill-rule="evenodd"
										clip-rule="evenodd"
										d="M11 2C11 1.44772 11.4477 1 12 1H14C14.5523 1 15 1.44772 15 2V14H15.5C15.7761 14 16 14.2239 16 14.5C16 14.7761 15.7761 15 15.5 15H0.5C0.223858 15 0 14.7761 0 14.5C0 14.2239 0.223858 14 0.5 14H1V11C1 10.4477 1.44772 10 2 10H4C4.55228 10 5 10.4477 5 11V14H6V7C6 6.44772 6.44772 6 7 6H9C9.55228 6 10 6.44772 10 7V14H11V2Z"
										fill="#94A3B8"
									/>
								</svg>

								<span class="mt-0.5"
									>Xem thống kê theo ngày</span
								>
							</div>
						</MenuItem>
					</MenuItems>
				</transition>
			</Menu>
		</div>
		<div
			v-if="
				Object.keys(studentObj).length > 0 ||
				Object.keys(staffObj).length > 0
			"
			class="mb-8"
		>
			<div class="flex items-center gap-2">
				<div
					v-if="Object.keys(studentObj).length > 0"
					class="
						text-sm
						inline-flex
						items-center
						py-1
						pl-3
						pr-2
						rounded-full
						bg-sienna-100
						text-neutral-900
						mr-2
						shadow-input
					"
				>
					{{ studentObj.name }}
					<button
						type="button"
						class="
							flex-shrink-0
							ml-2
							h-4
							w-4
							rounded-full
							inline-flex
							items-center
							justify-center
							text-neutral-700
							hover:text-neutral-900
						"
						@click="removeFilter('student')"
					>
						<svg width="7" height="7" viewBox="0 0 7 7" fill="none">
							<path
								fill-rule="evenodd"
								clip-rule="evenodd"
								d="M0.35861 0.35861C0.529464 0.187755 0.806474 0.187755 0.977328 0.35861L3.29297 2.67425L5.60861 0.35861C5.77946 0.187755 6.05647 0.187755 6.22733 0.35861C6.39818 0.529464 6.39818 0.806474 6.22733 0.977328L3.91169 3.29297L6.22733 5.60861C6.39818 5.77946 6.39818 6.05647 6.22733 6.22733C6.05647 6.39818 5.77946 6.39818 5.60861 6.22733L3.29297 3.91169L0.977328 6.22733C0.806474 6.39818 0.529464 6.39818 0.35861 6.22733C0.187755 6.05647 0.187755 5.77946 0.35861 5.60861L2.67425 3.29297L0.35861 0.977328C0.187755 0.806474 0.187755 0.529464 0.35861 0.35861Z"
								fill="currentColor"
							/>
						</svg>
					</button>
				</div>
				<div
					v-if="Object.keys(staffObj).length > 0"
					class="
						text-sm
						inline-flex
						items-center
						py-1
						pl-3
						pr-2
						rounded-full
						bg-sienna-100
						text-neutral-900
						mr-2
						shadow-input
					"
				>
					{{ staffObj.name }}
					<button
						type="button"
						class="
							flex-shrink-0
							ml-2
							h-4
							w-4
							rounded-full
							inline-flex
							items-center
							justify-center
							text-neutral-700
							hover:text-neutral-900
						"
						@click="removeFilter('staff')"
					>
						<svg width="7" height="7" viewBox="0 0 7 7" fill="none">
							<path
								fill-rule="evenodd"
								clip-rule="evenodd"
								d="M0.35861 0.35861C0.529464 0.187755 0.806474 0.187755 0.977328 0.35861L3.29297 2.67425L5.60861 0.35861C5.77946 0.187755 6.05647 0.187755 6.22733 0.35861C6.39818 0.529464 6.39818 0.806474 6.22733 0.977328L3.91169 3.29297L6.22733 5.60861C6.39818 5.77946 6.39818 6.05647 6.22733 6.22733C6.05647 6.39818 5.77946 6.39818 5.60861 6.22733L3.29297 3.91169L0.977328 6.22733C0.806474 6.39818 0.529464 6.39818 0.35861 6.22733C0.187755 6.05647 0.187755 5.77946 0.35861 5.60861L2.67425 3.29297L0.35861 0.977328C0.187755 0.806474 0.187755 0.529464 0.35861 0.35861Z"
								fill="currentColor"
							/>
						</svg>
					</button>
				</div>
				<div
					class="
						inline-flex
						items-center
						py-0.5
						rounded-full
						font-semibold
						cursor-pointer
						text-sm text-neutral-500
						hover:text-neutral-700
					"
					@click="removeFilter('all')"
				>
					Xoá hết bộ lọc
				</div>
			</div>
		</div>
		<div class="flex items-start justify-between gap-8 md-down:flex-col">
			<div class="md:hidden w-full">
				<div class="relative mb-6">
					<div
						class="absolute inset-0 flex items-center"
						aria-hidden="true"
					>
						<div class="w-full border-t border-neutral-300" />
					</div>
					<div class="relative flex justify-center">
						<div
							class="
								bg-body
								px-2
								text-neutral-600
								font-semibold
								whitespace-nowrap
								flex flex-nowrap
								items-center
								text-2xl
								justify-center
								gap-6
							"
						>
							<div class="cursor-pointer" @click="prevDay">
								<svg
									width="8"
									height="12"
									viewBox="0 0 8 12"
									fill="none"
									xmlns="http://www.w3.org/2000/svg"
								>
									<path
										d="M6.5 1L1.5 6L6.5 11"
										stroke="#48566A"
										stroke-width="2"
										stroke-linecap="round"
										stroke-linejoin="round"
									/>
								</svg>
							</div>
							<div v-if="date">
								{{ dateTitle }}
							</div>
							<div class="cursor-pointer" @click="nextDay">
								<svg
									width="8"
									height="12"
									viewBox="0 0 8 12"
									fill="none"
									xmlns="http://www.w3.org/2000/svg"
								>
									<path
										d="M1.5 11L6.5 6L1.5 1"
										stroke="#48566A"
										stroke-width="2"
										stroke-linecap="round"
										stroke-linejoin="round"
									/>
								</svg>
							</div>
						</div>
					</div>
				</div>
				<div>
					<v-date-picker
						v-model="date"
						is-expanded
						:attributes="attrs"
						ref="timeSlotDatepicker"
					/>
				</div>
			</div>
			<div class="w-full">
				<div class="relative md-down:hidden">
					<div
						class="absolute inset-0 flex items-center"
						aria-hidden="true"
					>
						<div class="w-full border-t border-neutral-300" />
					</div>
					<div class="relative flex justify-center">
						<div
							class="
								bg-body
								px-2
								text-neutral-600
								font-semibold
								whitespace-nowrap
								flex flex-nowrap
								items-center
								text-2xl
								justify-center
								gap-6
							"
						>
							<div class="cursor-pointer" @click="prevDay">
								<svg
									width="8"
									height="12"
									viewBox="0 0 8 12"
									fill="none"
									xmlns="http://www.w3.org/2000/svg"
								>
									<path
										d="M6.5 1L1.5 6L6.5 11"
										stroke="#48566A"
										stroke-width="2"
										stroke-linecap="round"
										stroke-linejoin="round"
									/>
								</svg>
							</div>
							<div v-if="date">
								{{ dateTitle }}
							</div>
							<div class="cursor-pointer" @click="nextDay">
								<svg
									width="8"
									height="12"
									viewBox="0 0 8 12"
									fill="none"
									xmlns="http://www.w3.org/2000/svg"
								>
									<path
										d="M1.5 11L6.5 6L1.5 1"
										stroke="#48566A"
										stroke-width="2"
										stroke-linecap="round"
										stroke-linejoin="round"
									/>
								</svg>
							</div>
						</div>
					</div>
				</div>
				<div class="border-b border-neutral-200">
					<nav class="-mb-px flex space-x-8 tabs" aria-label="Tabs">
						<router-link
							v-for="tab in tabsMenu"
							:key="tab.id"
							:to="tab.link"
							class="
								border-transparent
								text-neutral-500
								hover:text-yellow-700 hover:border-yellow-500
								whitespace-nowrap
								py-3
								px-1
								border-b-2
								font-semibold
							"
						>
							<div class="flex items-center gap-2">
								<div>
									<DaIcon :name="tab.icon" class="h-4 w-4" />
								</div>
								<div>{{ tab.name }}</div>
							</div>
						</router-link>
					</nav>
				</div>
				<div class="search-input w-ful relative mb-4 md:hidden mt-6">
					<div class="bg-white select-tabs w-full">
						<Multiselect
							v-model="studentSelectedDropdown"
							deselect-label="Bỏ chọn"
							track-by="id"
							:options="
								students?.map((s) => {
									return {
										nameSearch: toEnglish(s?.name),
										...s,
									};
								})
							"
							:searchable="true"
							label="nameSearch"
							selectLabel=""
							placeholder="Tìm kiếm theo học sinh"
						>
							<template #noResult>
								Không tìm thấy kết quả
							</template>
							<template v-slot:option="props">
								<div class="flex flex-nowrap items-center">
									{{ props.option.name }}
								</div>
							</template>
							<template v-slot:singleLabel="{ option }">
								<div
									class="
										flex flex-nowrap
										items-center
										font-medium
									"
								>
									{{ option.name }}
								</div>
							</template>
						</Multiselect>
					</div>
				</div>
				<div class="search-input w-ful relative mb-4 md:hidden mt-6">
					<div class="bg-white select-tabs w-full">
						<Multiselect
							v-model="staffSelectedDropdown"
							deselect-label="Bỏ chọn"
							track-by="id"
							:options="
								staffs?.map((s) => {
									return {
										nameSearch: toEnglish(s?.name),
										...s,
									};
								})
							"
							:searchable="true"
							label="nameSearch"
							selectLabel=""
							placeholder="Tìm kiếm theo TLV"
						>
							<template #noResult>
								Không tìm thấy kết quả
							</template>
							<template v-slot:option="props">
								<div class="flex flex-nowrap items-center">
									{{ props.option.name }}
								</div>
							</template>
							<template v-slot:singleLabel="{ option }">
								<div
									class="
										flex flex-nowrap
										items-center
										font-medium
									"
								>
									{{ option.name }}
								</div>
							</template>
						</Multiselect>
					</div>
				</div>
				<div class="md:overflow-y-auto">
					<router-view
						class="md:h-screen"
						:data="timeTableData"
						:data-group="timeTableDataGroup"
						:group_classes="group_classes"
						:staffs="staffsInGroup"
						:class_sessions="class_sessions"
						:staffs_map="staffs_map"
						:students_map="students_map"
						:student-selected="studentSelected"
						:group-selected="groupSelected"
						@selectedGroup="selectedGroup"
						@selectedStudent="selectedStudent"
					/>
				</div>
			</div>
			<div class="w-full md:w-96 md-down:hidden" style="min-width: 16rem">
				<div class="search-input w-ful relative mb-4">
					<div
						class="filter-input flex items-center justify-between"
						@click="sideOverShow = true"
					>
						<div>Tìm kiếm theo học sinh, giáo viên</div>
						<img src="@/assets/images/icons/search.png" alt="" />
					</div>
				</div>
				<div style="height: 72vh; overflow: auto">
					<div>
						<v-date-picker
							v-model="date"
							is-expanded
							:attributes="attrs"
							ref="timeSlotDatepicker"
						/>
					</div>
					<div
						v-if="studentSelected"
						class="
							w-full
							shadow-sm
							bg-yellow-50
							rounded-lg
							overflow-hidden
							mt-4
							p-4
							relative
						"
					>
						<div class="flex flex-nowrap gap-4">
							<div class="flex items-start">
								<div
									v-if="studentSelected.student_id"
									class="h-9 w-9"
								>
									<img
										class="
											shadow-sm
											inline-block
											h-9
											w-9
											rounded-full
										"
										:src="
											getStudent(
												studentSelected.student_id
											)?.profile_photo?.w200
										"
										alt=""
										v-if="
											getStudent(
												studentSelected.student_id
											)?.profile_photo?.w200
										"
									/>
									<span
										v-else
										class="
											inline-flex
											items-center
											justify-center
											h-9
											w-9
											rounded-full
										"
										:style="{
											background: getStudent(
												studentSelected.student_id
											)?.profile_photo?.default?.color,
										}"
									>
										<span
											class="
												font-medium
												leading-none
												text-white text-xs
											"
											>{{
												getStudent(
													studentSelected.student_id
												)?.profile_photo?.default
													?.initial
											}}</span
										>
									</span>
								</div>
								<div v-else>
									<img
										class="
											shadow-sm
											inline-block
											h-9
											w-9
											rounded-full
										"
										:src="
											studentSelected.student
												?.profile_photo?.w200
										"
										alt=""
										v-if="
											studentSelected.student
												?.profile_photo?.w200
										"
									/>
									<span
										v-else
										class="
											inline-flex
											items-center
											justify-center
											h-9
											w-9
											rounded-full
										"
										:style="{
											background:
												studentSelected.student
													?.profile_photo?.default
													?.color,
										}"
									>
										<span
											class="
												font-medium
												leading-none
												text-white text-xs
											"
											>{{
												studentSelected.student
													?.profile_photo?.default
													?.initial
											}}</span
										>
									</span>
								</div>
							</div>
							<div class="w-full">
								<div
									class="
										text-orange-600
										font-semibold
										truncate
										text-lg
									"
									v-if="studentSelected.student_id"
								>
									{{
										getStudent(studentSelected.student_id)
											?.name
									}}
								</div>
								<div
									class="
										text-orange-600
										font-semibold
										truncate
										text-lg
									"
									v-else
								>
									{{ studentSelected.student?.name }}
								</div>
								<div
									class="flex gap-2 border-b w-full py-4"
									v-if="
										studentSelected.display_start_slot &&
										studentSelected.display_end_slot
									"
								>
									<div>
										<svg
											width="16"
											height="16"
											viewBox="0 0 16 16"
											fill="none"
											xmlns="http://www.w3.org/2000/svg"
										>
											<path
												fill-rule="evenodd"
												clip-rule="evenodd"
												d="M16 8C16 12.4183 12.4183 16 8 16C3.58172 16 0 12.4183 0 8C0 3.58172 3.58172 0 8 0C12.4183 0 16 3.58172 16 8ZM8 3.5C8 3.22386 7.77614 3 7.5 3C7.22386 3 7 3.22386 7 3.5V9C7 9.17943 7.09614 9.3451 7.25193 9.43412L10.7519 11.4341C10.9917 11.5711 11.2971 11.4878 11.4341 11.2481C11.5711 11.0083 11.4878 10.7029 11.2481 10.5659L8 8.70984V3.5Z"
												fill="#6366F1"
											/>
										</svg>
									</div>
									<div>
										<div
											class="
												text-neutral-600
												font-semibold
											"
										>
											<span
												>{{
													studentSelected.display_start_slot
												}}
												-
												{{
													studentSelected.display_end_slot
												}}</span
											>
										</div>
										<div v-if="studentSelected.staff_ids">
											<div
												class="flex items-center mt-2"
												v-for="staff_id in studentSelected.staff_ids"
												:key="staff_id"
											>
												<div>
													<img
														class="
															shadow-sm
															inline-block
															h-9
															w-9
															rounded-full
														"
														:src="
															getStaff(staff_id)
																?.profile_photo
																?.w200
														"
														alt=""
														v-if="
															getStaff(staff_id)
																?.profile_photo
																?.w200
														"
													/>
													<span
														v-else
														class="
															inline-flex
															items-center
															justify-center
															h-9
															w-9
															rounded-full
														"
														:style="{
															background:
																getStaff(
																	staff_id
																)?.profile_photo
																	?.default
																	?.color,
														}"
													>
														<span
															class="
																font-medium
																leading-none
																text-white
																text-xs
															"
															>{{
																getStaff(
																	staff_id
																)?.profile_photo
																	?.default
																	?.initial
															}}</span
														>
													</span>
												</div>
												<div
													class="
														font-semibold
														text-blue-600
														ml-4
													"
												>
													{{
														getStaff(staff_id).name
													}}
												</div>
											</div>
										</div>
									</div>
								</div>
								<div
									class="border-b w-full py-4"
									v-if="
										studentSelected.unscheduled_note ||
										studentSelected.leave_note ||
										studentSelected.staff_change_note ||
										studentSelected.slot_change_note
									"
								>
									<div class="font-semibold text-neutral-600">
										Ghi chú
									</div>
									<div class="text-neutral-600">
										<div
											v-if="
												studentSelected.unscheduled_note
											"
										>
											<span class="font-semibold"
												>Phát sinh: </span
											>{{
												studentSelected.unscheduled_note
											}}
										</div>
										<div v-if="studentSelected.leave_note">
											<span class="font-semibold"
												>Báo nghỉ: </span
											>{{ studentSelected.leave_note }}
										</div>
										<div
											v-if="
												studentSelected.staff_change_note
											"
										>
											<span class="font-semibold"
												>Đổi TLV: </span
											>{{
												studentSelected.staff_change_note
											}}
										</div>
										<div
											v-if="
												studentSelected.slot_change_note
											"
										>
											<span class="font-semibold"
												>Đổi giờ:</span
											>
											{{
												studentSelected.slot_change_note
											}}
										</div>
									</div>
								</div>
								<div class="mt-4 flex items-center gap-4">
									<div>
										<template
											v-if="
												studentSelected &&
												studentSelected.state ===
													'cancelled'
											"
										>
											<div
												v-if="
													studentSelected.student_on_leave
												"
												class="
													rounded-full
													border border-sienna-400
													flex
													items-center
													justify-center
													text-sm text-sienna-600
													font-semibold
													w-fit
													px-3
													py-1
												"
											>
												HS nghỉ
											</div>
											<div
												v-else
												class="
													rounded-full
													border border-sienna-400
													flex
													items-center
													justify-center
													text-sm text-sienna-600
													font-semibold
													w-fit
													px-3
													py-1
												"
											>
												TLV nghỉ
											</div>
										</template>
										<div v-else>
											<div
												v-if="
													studentSelected &&
													studentSelected.state ===
														'created'
												"
												class="
													rounded-full
													flex
													items-center
													justify-center
													bg-green-100
													text-xs text-green-600
													font-semibold
													w-fit
													px-3
													py-1
												"
											>
												Đã học
											</div>
											<div
												v-if="
													studentSelected &&
													studentSelected.state ===
														'ongoing'
												"
												class="
													rounded-full
													flex
													items-center
													justify-center
													bg-blue-100
													text-xs text-blue-600
													font-semibold
													w-fit
													px-3
													py-1
												"
											>
												Đang học
											</div>
											<div
												v-if="
													studentSelected &&
													studentSelected.state !==
														'past' &&
													studentSelected.state !==
														'cancelled' &&
													studentSelected.state !==
														'created' &&
													studentSelected.state !==
														'ongoing'
												"
												class="
													rounded-full
													flex
													items-center
													justify-center
													bg-neutral-100
													text-xs text-neutral-600
													font-semibold
													w-fit
													px-3
													py-1
												"
											>
												Chưa học
											</div>
										</div>
									</div>
									<div>
										<div
											v-if="!studentSelected.scheduled"
											class="
												rounded-full
												border border-sienna-400
												flex
												items-center
												justify-center
												text-xs text-sienna-600
												font-semibold
												w-fit
												px-2.5
												py-0.5
											"
										>
											Phát sinh
										</div>
										<div
											v-else
											class="
												w-fit
												text-xs
												font-semibold
												text-neutral-600
												px-2.5
												py-0.5
												rounded-full
												border border-neutral-300
												flex
												items-center
												justify-center
											"
										>
											Cố định
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div
						v-if="groupSelected"
						class="
							w-full
							shadow-sm
							bg-yellow-50
							rounded-lg
							overflow-hidden
							mt-4
							p-4
							relative
						"
					>
						<div class="flex flex-nowrap gap-4">
							<div class="w-full">
								<UserGroup
									:data="
										group_classes.find(
											(g) =>
												g.id ===
												groupSelected?.group_class_id
										)?.students
									"
								/>
								<div
									class="
										font-semibold
										text-neutral-700
										-mb-2
										mt-3
									"
								>
									{{
										group_classes.find(
											(g) =>
												g.id ===
												groupSelected?.group_class_id
										)?.name
									}}
								</div>
								<div
									class="flex gap-2 border-b w-full py-4"
									v-if="
										groupSelected.display_start_slot &&
										groupSelected.display_end_slot
									"
								>
									<div>
										<svg
											width="16"
											height="16"
											viewBox="0 0 16 16"
											fill="none"
											xmlns="http://www.w3.org/2000/svg"
										>
											<path
												fill-rule="evenodd"
												clip-rule="evenodd"
												d="M16 8C16 12.4183 12.4183 16 8 16C3.58172 16 0 12.4183 0 8C0 3.58172 3.58172 0 8 0C12.4183 0 16 3.58172 16 8ZM8 3.5C8 3.22386 7.77614 3 7.5 3C7.22386 3 7 3.22386 7 3.5V9C7 9.17943 7.09614 9.3451 7.25193 9.43412L10.7519 11.4341C10.9917 11.5711 11.2971 11.4878 11.4341 11.2481C11.5711 11.0083 11.4878 10.7029 11.2481 10.5659L8 8.70984V3.5Z"
												fill="#6366F1"
											/>
										</svg>
									</div>
									<div>
										<div
											class="
												text-neutral-600
												font-semibold
											"
										>
											<span
												>{{
													groupSelected.display_start_slot
												}}
												-
												{{
													groupSelected.display_end_slot
												}}</span
											>
										</div>
										<div v-if="groupSelected.staff_ids">
											<div
												class="flex items-center mt-2"
												v-for="staff_id in groupSelected.staff_ids"
												:key="staff_id"
											>
												<div>
													<img
														class="
															shadow-sm
															inline-block
															h-9
															w-9
															rounded-full
														"
														:src="
															getStaffInGroup(
																staff_id
															)?.profile_photo
																?.w200
														"
														alt=""
														v-if="
															getStaffInGroup(
																staff_id
															)?.profile_photo
																?.w200
														"
													/>
													<span
														v-else
														class="
															inline-flex
															items-center
															justify-center
															h-9
															w-9
															rounded-full
														"
														:style="{
															background:
																getStaffInGroup(
																	staff_id
																)?.profile_photo
																	?.default
																	?.color,
														}"
													>
														<span
															class="
																font-medium
																leading-none
																text-white
																text-xs
															"
															>{{
																getStaffInGroup(
																	staff_id
																)?.profile_photo
																	?.default
																	?.initial
															}}</span
														>
													</span>
												</div>
												<div
													class="
														font-semibold
														text-blue-600
														ml-4
													"
												>
													{{
														getStaffInGroup(
															staff_id
														)?.name
													}}
												</div>
											</div>
										</div>
									</div>
								</div>
								<div
									class="border-b w-full py-4"
									v-if="
										groupSelected.unscheduled_note ||
										groupSelected.leave_note ||
										groupSelected.staff_change_note ||
										groupSelected.slot_change_note
									"
								>
									<div class="font-semibold text-neutral-600">
										Ghi chú
									</div>
									<div class="text-neutral-600">
										<div
											v-if="
												groupSelected.unscheduled_note
											"
										>
											<span class="font-semibold"
												>Phát sinh: </span
											>{{
												groupSelected.unscheduled_note
											}}
										</div>
										<div v-if="groupSelected.leave_note">
											<span class="font-semibold"
												>Báo nghỉ: </span
											>{{ groupSelected.leave_note }}
										</div>
										<div
											v-if="
												groupSelected.staff_change_note
											"
										>
											<span class="font-semibold"
												>Đổi TLV: </span
											>{{
												groupSelected.staff_change_note
											}}
										</div>
										<div
											v-if="
												groupSelected.slot_change_note
											"
										>
											<span class="font-semibold"
												>Đổi giờ:</span
											>
											{{ groupSelected.slot_change_note }}
										</div>
									</div>
								</div>
								<div class="mt-4 flex items-center gap-4">
									<div>
										<template
											v-if="
												groupSelected &&
												groupSelected.state ===
													'cancelled'
											"
										>
											<div
												v-if="
													groupSelected.student_on_leave
												"
												class="
													rounded-full
													border border-sienna-400
													flex
													items-center
													justify-center
													text-sm text-sienna-600
													font-semibold
													w-fit
													px-3
													py-1
												"
											>
												HS nghỉ
											</div>
											<div
												v-else
												class="
													rounded-full
													border border-sienna-400
													flex
													items-center
													justify-center
													text-sm text-sienna-600
													font-semibold
													w-fit
													px-3
													py-1
												"
											>
												TLV nghỉ
											</div>
										</template>
										<div v-else>
											<div
												v-if="
													groupSelected &&
													groupSelected.state ===
														'created'
												"
												class="
													rounded-full
													flex
													items-center
													justify-center
													bg-green-100
													text-xs text-green-600
													font-semibold
													w-fit
													px-3
													py-1
												"
											>
												Đã học
											</div>
											<div
												v-if="
													groupSelected &&
													groupSelected.state ===
														'ongoing'
												"
												class="
													rounded-full
													flex
													items-center
													justify-center
													bg-blue-100
													text-xs text-blue-600
													font-semibold
													w-fit
													px-3
													py-1
												"
											>
												Đang học
											</div>
											<div
												v-if="
													groupSelected &&
													groupSelected.state !==
														'past' &&
													groupSelected.state !==
														'cancelled' &&
													groupSelected.state !==
														'created' &&
													groupSelected.state !==
														'ongoing'
												"
												class="
													rounded-full
													flex
													items-center
													justify-center
													bg-neutral-100
													text-xs text-neutral-600
													font-semibold
													w-fit
													px-3
													py-1
												"
											>
												Chưa học
											</div>
										</div>
									</div>
									<div>
										<div
											v-if="!groupSelected.scheduled"
											class="
												rounded-full
												border border-sienna-400
												flex
												items-center
												justify-center
												text-xs text-sienna-600
												font-semibold
												w-fit
												px-2.5
												py-0.5
											"
										>
											Phát sinh
										</div>
										<div
											v-else
											class="
												w-fit
												text-xs
												font-semibold
												text-neutral-600
												px-2.5
												py-0.5
												rounded-full
												border border-neutral-300
												flex
												items-center
												justify-center
											"
										>
											Cố định
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div
						v-if="isGroup"
						class="
							w-full
							shadow-sm
							bg-white
							rounded-lg
							overflow-hidden
							mt-4
						"
					>
						<div class="font-semibold text-gray-600 p-4">
							Thống kê theo ngày
						</div>
						<div
							class="
								p-4
								flex
								items-center
								justify-between
								border-t border-yellow-50
							"
						>
							<div class="text-sm">
								Giờ can thiệp nhóm / dự kiến
							</div>
							<div
								class="text-3xl font-semibold text-neutral-600"
							>
								{{ groupStats.total_non_cancelled_hours }}/{{
									groupStats.total_hours
								}}
							</div>
						</div>
						<div
							class="
								p-4
								flex
								items-center
								justify-between
								border-t border-yellow-50
							"
						>
							<div class="text-sm">
								Giờ can thiệp nhóm phát sinh
							</div>
							<div
								class="text-3xl font-semibold text-neutral-600"
							>
								{{ groupStats.total_unscheduled_hours }}
							</div>
						</div>
						<div
							class="
								p-4
								flex
								items-center
								justify-between
								border-t border-yellow-50
							"
						>
							<div class="text-sm">
								Giờ can thiệp nhóm báo nghỉ
							</div>
							<div
								class="text-3xl font-semibold text-neutral-600"
							>
								{{ groupStats.total_cancelled_hours }}
							</div>
						</div>
						<div
							class="
								p-4
								flex
								items-center
								justify-between
								border-t border-yellow-50
							"
						>
							<div class="text-sm">H.S bán trú / dự kiến</div>
							<div
								class="text-3xl font-semibold text-neutral-600"
							>
								{{
									Number(
										stats.total_non_cancelled +
											stats.total_unscheduled
									) || '0'
								}}
								/
								{{ stats.total || '0' }}
							</div>
						</div>
					</div>
					<div
						v-else
						class="
							w-full
							shadow-sm
							bg-white
							rounded-lg
							overflow-hidden
							mt-4
						"
					>
						<div class="font-semibold text-gray-600 p-4">
							Thống kê theo ngày
						</div>
						<div
							class="
								p-4
								flex
								items-center
								justify-between
								border-t border-yellow-50
							"
						>
							<div class="text-sm">Giờ can thiệp/ dự kiến</div>
							<div
								class="text-3xl font-semibold text-neutral-600"
							>
								{{ stats.total_non_cancelled_hours }}/{{
									stats.total_hours
								}}
							</div>
						</div>
						<div
							class="
								p-4
								flex
								items-center
								justify-between
								border-t border-yellow-50
							"
						>
							<div class="text-sm">Giờ can thiệp phát sinh</div>
							<div
								class="text-3xl font-semibold text-neutral-600"
							>
								{{ stats.total_unscheduled_hours }}
							</div>
						</div>
						<div
							class="
								p-4
								flex
								items-center
								justify-between
								border-t border-yellow-50
							"
						>
							<div class="text-sm">Giờ can thiệp báo nghỉ</div>
							<div
								class="text-3xl font-semibold text-neutral-600"
							>
								{{ stats.total_cancelled_hours }}
							</div>
						</div>
						<div
							class="
								p-4
								flex
								items-center
								justify-between
								border-t border-yellow-50
							"
						>
							<div class="text-sm">H.S bán trú/ dự kiến</div>
							<div
								class="text-3xl font-semibold text-neutral-600"
							>
								{{
									Number(
										stats.total_non_cancelled +
											stats.total_unscheduled
									)
								}}
								/
								{{ stats.total }}
							</div>
						</div>
					</div>
					<div class="mt-4">
						<div class="flex gap-2 items-center">
							<div class="note normal"></div>
							<div class="text-sm">Buổi học thường</div>
						</div>
						<div class="flex gap-2 items-center mt-4">
							<div class="note selected"></div>
							<div class="text-sm">Buổi học dang chọn</div>
						</div>
						<div class="flex gap-2 items-center mt-4">
							<div class="note duplicate"></div>
							<div class="text-sm">Buổi học bị trùng</div>
						</div>
						<div class="flex gap-2 items-center mt-4">
							<div class="note off"></div>
							<div class="text-sm">Buổi học được báo nghỉ</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
	<div v-else class="fixed top-1/2 left-1/2">
		<Loading />
	</div>
</template>

<script>
import { defineComponent } from 'vue';
import DaIcon from '@/components/elements/daIcon/DaIcon.vue';
import CreateTimeSlot from '@/views/timeSlot/features/CreateTimeSlot.vue';
import { $api } from '@/services';
import dayjs from 'dayjs';
import Multiselect from '@suadelabs/vue3-multiselect';
import { _ROUTER_NAME } from '@/enums';
import CreateUnScheduleSession from '@/views/sessionManagement/features/createUnScheduleSession';
import Loading from '@/components/common/Loading';
import { Menu, MenuButton, MenuItem, MenuItems } from '@headlessui/vue';
import { toEnglish } from '@/helper/toEnglish';
import UserGroup from '@/components/elements/UserGroup.vue';
import SideOver from '@/views/timeSlot/features/SideOver.vue';
import CreateTimeSlotGroupClass from '@/views/sessionManagement/features/CreateTimeSlotGroupClass.vue';

export default defineComponent({
	name: 'TimeSlot',

	components: {
		CreateTimeSlotGroupClass,
		UserGroup,
		Loading,
		CreateUnScheduleSession,
		CreateTimeSlot,
		DaIcon,
		Multiselect,
		Menu,
		MenuItem,
		MenuItems,
		MenuButton,
		SideOver,
	},

	data() {
		return {
			isLoading: true,
			date: new Date(),
			datePickerData: new Date(),
			isShow: false,
			attrs: [
				{
					key: 'today',
					highlight: {
						fillMode: 'outline',
					},
					dates: new Date(),
				},
			],
			params: {
				date: dayjs().format('YYYY-MM-DD'),
			},
			timeTableData: [],
			timeTableDataGroup: [],
			class_sessions: [],
			group_classes: [],
			staffs_map: {},
			students_map: {},
			students: [],
			studentSelected: null,
			groupSelected: null,
			studentSelectedDropdown: null,
			staffs: [],
			staffsInGroup: [],
			staffSelected: null,
			staffSelectedDropdown: null,
			stats: {
				total_non_cancelled: 0,
				total_unscheduled: 0,
				total: 1,
				total_hours: '0',
				total_non_cancelled_hours: '0',
				total_students: 0,
				total_unscheduled_hours: '0',
			},
			groupStats: {
				total_non_cancelled: 0,
				total_unscheduled: 0,
				total: 1,
				total_hours: '0',
				total_non_cancelled_hours: '0',
				total_students: 0,
				total_unscheduled_hours: '0',
			},
			showStats: false,
			sideOverShow: false,
			studentObj: {},
			staffObj: {},
		};
	},

	created() {
		this.fetchStudents();
		this.fetchStaffs();
		this.fetchTimeTableOnDay();
		this.fetchTimeTableDaycareOnDay();
		this.fetchTimeTableGroupOnDay();
		this.$router.push(this.tabsMenu[0].link);
	},

	computed: {
		isClassed() {
			return [
				_ROUTER_NAME.SCHEDULE_TIMESLOT,
				_ROUTER_NAME.SCHEDULE_TIMESLOT__CLASS,
				_ROUTER_NAME.SESSION_MANAGEMENT,
				_ROUTER_NAME.SESSION_MANAGEMENT__CLASS,
			].includes(this.$route.name);
		},

		isGroup() {
			return [
				_ROUTER_NAME.SCHEDULE_TIMESLOT__GROUP_CLASSES,
				_ROUTER_NAME.SESSION_MANAGEMENT__GROUP_CLASS,
			].includes(this.$route.name);
		},

		dateTitle() {
			const day = dayjs(this.date).get('day');
			const result = day !== 0 ? 'Thứ ' + Number(day + 1) : 'Chủ nhật';
			return result + ' - ' + dayjs(this.date).format('DD/MM/YYYY');
		},

		org() {
			return this.$store.state.profile.current_org;
		},

		tabsMenu() {
			const tabs = [];
			if (this.org.enable_personal_schedule) {
				tabs.push({
					id: 0,
					link: '/schedule/timeslot',
					name: 'Học cá nhân',
					icon: 'das--person',
				});
			}
			if (
				this.org.has_day_care &&
				this.org.exists_day_care_class &&
				this.org.enable_day_care_class_schedule
			) {
				tabs.push({
					id: 1,
					link: '/schedule/timeslot/daycare',
					name: 'Học bán trú',
					// eslint-disable-next-line no-mixed-spaces-and-tabs
					icon: 'das--spoon',
					// eslint-disable-next-line no-mixed-spaces-and-tabs
				});
			}
			tabs.push({
				id: 2,
				link: '/schedule/timeslot/group',
				name: 'Học nhóm',
				// eslint-disable-next-line no-mixed-spaces-and-tabs
				icon: 'das--users',
				// eslint-disable-next-line no-mixed-spaces-and-tabs
			});
			return tabs;
		},
	},

	watch: {
		date: {
			deep: true,
			handler(value) {
				if (value) {
					this.params = {
						...this.params,
						date: this.getDateYYYYMMDDSubmit(value),
					};
					this.fetchTimeTableOnDay();
					this.fetchTimeTableDaycareOnDay();
					this.fetchTimeTableGroupOnDay();
				}
			},
		},
	},

	methods: {
		toEnglish,
		getDateYYYYMMDDSubmit(date) {
			if (date) {
				const newDate = new Date(date).valueOf();
				const data = dayjs(newDate).format('YYYY-MM-DD');
				if (data.toLowerCase() === 'invalid date') {
					return null;
				}
				return data;
			}
			return '';
		},

		prevDay() {
			this.date = dayjs(this.date).subtract(1, 'day').toDate();
		},

		nextDay() {
			this.date = dayjs(this.date).add(1, 'day').toDate();
		},

		filterSubmit(value) {
			this.params = {
				...this.params,
				student_id: value.student?.id ?? null,
				staff_id: value.staff?.id ?? null,
			};
			this.studentObj = value.student;
			this.staffObj = value.staff;
			console.log('value', value);
			this.fetchTimeTableOnDay();
			this.fetchTimeTableDaycareOnDay();
			this.fetchTimeTableGroupOnDay();
		},
		removeFilter(type) {
			if (type == 'student') this.studentObj = {};
			if (type == 'staff') this.staffObj = {};
			if (type == 'all') {
				this.studentObj = {};
				this.staffObj = {};
			}
			this.params = {
				...this.params,
				student_id: this.studentObj?.id ?? null,
				staff_id: this.staffObj?.id ?? null,
			};
			this.fetchTimeTableOnDay();
			this.fetchTimeTableDaycareOnDay();
			this.fetchTimeTableGroupOnDay();
		},

		async fetchTimeTableOnDay() {
			try {
				const {
					data: { stats, data, staffs_map, students_map },
				} = await $api.schedule.fetchTimetableOnDay(this.params);
				this.stats = stats;
				this.staffs_map = staffs_map || {};
				this.students_map = students_map || {};
				this.timeTableData = data;
				this.isLoading = false;
			} catch (e) {
				console.log(e);
			}
		},

		async fetchTimeTableDaycareOnDay() {
			try {
				const {
					data: {
						data: { student_class_sessions },
					},
				} = await $api.schedule.fetchTimetableScheduleOnDay(
					this.params
				);
				this.class_sessions = student_class_sessions;
			} catch (e) {
				console.log(e);
			}
		},

		async fetchTimeTableGroupOnDay() {
			try {
				const {
					data: { stats, data, group_classes, staffs },
				} = await $api.groupClasses.fetchTimeTableGroupOnDay(
					this.params
				);
				this.groupStats = stats;
				this.timeTableDataGroup = data;
				this.group_classes = group_classes;
				this.staffsInGroup = staffs;
				this.isLoading = false;
			} catch (e) {
				console.log(e);
			}
		},

		async fetchStudents() {
			let role =
				this.$store.state.profile.staff.role === 'teacher'
					? 'staff'
					: 'org_admin';
			try {
				const res = await $api.student.getStudents(role, {});
				this.students = res?.data?.data || [];
			} catch (e) {
				console.log(e);
			}
		},

		async fetchStaffs() {
			try {
				const {
					data: { data },
				} = await $api.staff.getEmployees('');
				this.staffs = data;
			} catch (err) {
				console.log(err);
			}
		},

		selectedStudent(student) {
			if (
				!this.studentSelected ||
				this.studentSelected.student_id !== student.student_id
			) {
				this.studentSelected = student;
			} else {
				this.studentSelected = null;
			}
		},

		selectedGroup(group) {
			if (
				!this.groupSelected ||
				this.groupSelected.group_class_id !== group.group_class_id
			) {
				this.groupSelected = group;
			} else {
				this.groupSelected = null;
			}
		},

		getStaff(staff_id) {
			return this.staffs_map[staff_id]?.[0];
		},

		getStaffInGroup(staff_id) {
			return this.staffsInGroup.find((staff) => staff.id === staff_id);
		},

		getStudent(student_id) {
			return this.students_map[student_id]?.[0];
		},

		successCreated() {
			this.isShow = false;
			this.fetchTimeTableDaycareOnDay();
			this.fetchTimeTableOnDay();
		},

		closeStats() {
			this.showStats = false;
			this.studentSelected = null;
		},
	},
});
</script>

<style scoped lang="scss">
.tabs {
	.router-link-exact-active {
		@apply text-yellow-700 border-yellow-500;
	}
}
.filter-input {
	border-radius: 4px;
	border: 0.5px solid var(--Gray-50, #f8fafc);
	background: var(--Shades-White, #fff);
	color: var(--Gray-400, #94a3b8);
	font-size: 14px;
	font-weight: 400;
	line-height: 20px;
	box-shadow: 0px 0px 1px 0px rgba(50, 50, 71, 0.2),
		0px 1px 2px 0px rgba(50, 50, 71, 0.08);
	padding: 14px 16px 10px 16px;
}
.note {
	border-radius: 4px;
	box-shadow: 0px 3px 8px -1px #3232470d;
	width: 40px;
	height: 24px;
}
.normal {
	border: 1px solid #c7d2fe;
	background: #ffffff;
}
.selected {
	border: 1px solid #e5a42b;
	background: #fef8ed;
}
.duplicate {
	border: 1px solid #efb8ac;
	background: #fcf1ee;
}
.off {
	border: 1px solid #cbd5e1;
	background: #e1e7ef;
}
</style>
