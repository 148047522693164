
import { Vue } from 'vue-class-component';
import { PropSync, Prop } from 'vue-property-decorator';

export default class ScheduleConfirm extends Vue {
	@Prop() isOff!: boolean;
	@PropSync('type') _type!: string;

	//----------------------- 🤍 Methods 🤍 -----------------------//
	submit() {
		this.$emit('submit');
	}

	get featureName() {
		switch (this._type) {
			case 'enable_personal_schedule':
				return 'lịch học cá nhân';
			case 'enable_day_care_class_schedule':
				return 'lịch học bán trú';
			case 'enable_report_by_session':
				return 'báo cáo theo lịch học cá nhân';
			default:
				return 'lịch học';
		}
	}

	get titleNameOff() {
		switch (this._type) {
			case 'enable_personal_schedule':
				return 'Thời khóa biểu, quản lý chuyên cần, và báo cáo theo giờ học của phần can thiệp cá nhân sẽ bị vô hiệu hóa.';
			case 'enable_day_care_class_schedule':
				return 'Thời khóa biểu, quản lý chuyên cần, và báo cáo bán trú sẽ bị vô hiệu hóa.';
			case 'enable_report_by_session':
				return 'Chế độ tạo báo cáo sẽ trở về mặc định: Một báo cáo mỗi ngày. Lịch học vẫn được hiển thị theo thời khoá biểu.';
			default:
				return `Việc này sẽ vô hiệu hóa tất cả các tính năng liên quan đến lịch học, bao gồm thời khóa biểu, việc thực hiện báo cáo được nhiều buổi học cùng ngày, và cả việc thống kê số giờ học của học sinh, số giờ can thiệp của trị liệu viên. <br/> <br/> Xem hướng dẫn chỉnh sửa TKB <a href="https://wiki.luca.education/web-danh-cho-tri-lieu-vien/lich-hoc/thoi-khoa-bieu" class="text-green-500">Tại đây</a>`;
		}
	}

	get titleNameOn() {
		switch (this._type) {
			case 'enable_personal_schedule':
				return 'Thời khóa biểu, quản lý chuyên cần, và báo cáo theo giờ học của phần can thiệp cá nhân sẽ được kích hoạt.';
			case 'enable_day_care_class_schedule':
				return 'Thời khóa biểu, quản lý chuyên cần, và báo cáo bán trú sẽ được kích hoạt.';
			case 'enable_report_by_session':
				return 'Chế độ tạo báo cáo theo thời khóa biểu sẽ được kích hoạt.';
			default:
				return `Việc này sẽ kích hoạt tất cả các tính năng liên quan đến lịch học, bao gồm thời khóa biểu, việc thực hiện báo cáo được nhiều buổi học cùng ngày, và cả việc thống kê số giờ học của học sinh, số giờ can thiệp của trị liệu viên.`;
		}
	}
}
