
import { Options, setup, Vue } from 'vue-class-component';
import DaIcon from '@/components/elements/daIcon/DaIcon.vue';
import { _MODAL_TYPE as modalTypes, _MODAL_TYPE, _ROUTER_NAME } from '@/enums';
import { useStudent } from '@/features/student/useStudent';
import { useAssignments } from '@/features/assignment/useAssignments';
import { Menu, MenuButton, MenuItem, MenuItems } from '@headlessui/vue';
import { useStore } from 'vuex';
import DaModalFrame from '@/components/common/daModalFrame/DaModalFrame.vue';
import CreateStudent from '@/views/record/students/features/createStudent/CreateStudent.vue';
import { Watch } from 'vue-property-decorator';
import Loading from '@/components/common/Loading.vue';
import StudentState from '@/components/common/StudentState.vue';
import CreateStaffModal from '@/views/record/studentDetail/features/staffList/features/createStaffModal/CreateStaffModal.vue';
import { useGetEmployees } from '@/views/record/employee/services/getEmployees';
import InviteParentPopup from '@/views/record/studentDetail/features/inviteParent/InviteParentPopup.vue';
import ChangeStatus from '@/views/record/students/features/ChangeStatus.vue';

@Options({
	name: 'Students',
	components: {
		StudentState,
		Loading,
		CreateStudent,
		DaModalFrame,
		DaIcon,
		Menu,
		MenuItem,
		MenuItems,
		MenuButton,
		CreateStaffModal,
		InviteParentPopup,
		ChangeStatus,
	},
})
export default class Students extends Vue {
	//----------------------- 🤍 Data 🤍 -----------------------//
	change = false;
	students: any[] = [];
	studentData: any;
	full_students: any[] = [];
	staffsList: any[] = [];
	count = {
		closed: 0,
		total: 0,
	};
	modalIsOpen = false;
	studentFullName = '';
	error = '';
	status = 'active';
	modalType = '';
	isLoading = true;
	inProgress = false;
	id = '';
	isActiveMode = false;

	//----------------------- 🤍 Setup 🤍 -----------------------//
	setup = setup(() => {
		const store = useStore();
		const { error, loading, getStudents, createStudents, closeStudent } =
			useStudent();
		const { getEmployees } = useGetEmployees();
		const { createStaffAssignments } = useAssignments();
		return {
			error,
			loading,
			getStudents,
			store,
			createStudents,
			closeStudent,
			getEmployees,
			createStaffAssignments,
		};
	});
	searchQuery = '';
	typing: any = null;

	//----------------------- 🤍 Getters 🤍 -----------------------//
	get routerName() {
		return _ROUTER_NAME;
	}
	get store() {
		return this.setup.store;
	}
	get isAdmin() {
		return this.setup.store.getters.isAdmin;
	}
	get studentsFilter() {
		return this.students;
	}
	get modalTypes() {
		return modalTypes;
	}

	//----------------------- 🤍 Created 🤍 -----------------------//
	created() {
		this.fetchStudents();
		this.fetchStaffs();
	}

	changeStudentStatus(student: any) {
		this.studentData = student;
		this.modalIsOpen = true;
		this.modalType = _MODAL_TYPE.CHANGE;
	}
	closeStudent(isActive = false, data: any) {
		this.closeStudentSubmit({
			isActive: isActive,
			id: this.studentData.id,
			state: data?.state?.id || undefined,
		});
		this.fetchStudents();
		this.modalIsOpen = false;
	}
	async closeStudentSubmit(value: {
		isActive: boolean;
		id: string;
		state: string;
	}) {
		const res = await this.setup.closeStudent(
			value.id,
			value.isActive,
			value.state
		);
		if (res?.data) {
			await this.fetchStudents();
		}
		if (res?.data?.message) {
			await this.store.dispatch('setAlertMessage', {
				message: res?.data?.message,
			});
		}
	}
	async createStaffSubmit(value: { staff_id: string; main: boolean }) {
		const res = await this.setup.createStaffAssignments(
			this.id,
			value?.staff_id,
			value?.main
		);
		if (res?.data) {
			this.modalIsOpen = false;
			await this.fetchStudents();
			await this.store.dispatch('setAlertMessage', {
				message: 'Thêm giáo viên thành công',
			});
		}
	}

	openAddStaffModel(id: string) {
		this.id = id;
		this.modalIsOpen = true;
		this.modalType = _MODAL_TYPE.CREATE;
	}
	inviting(id: string, student_name: string) {
		this.id = id;
		this.studentFullName = student_name;
		this.modalIsOpen = true;
		this.modalType = _MODAL_TYPE.INVITE;
	}
	async fetchStudents() {
		let role =
			this.store.state.profile.staff.role === 'teacher'
				? 'staff'
				: 'org_admin';
		const studentList = await this.setup.getStudents(role, {
			state: this.status,
		});
		this.isLoading = false;
		this.count = studentList.count;
		this.students = studentList.data;
		this.full_students = studentList.data;
	}

	//----------------------- 🤍 Methods 🤍 -----------------------//
	statusLabel(status: string) {
		if (status === 'active') {
			return 'Kích hoạt';
		} else if (status !== 'active') {
			return 'Khóa';
		}
		return '';
	}

	openCreateModal() {
		this.modalIsOpen = true;
		this.modalType = _MODAL_TYPE.ADD;
	}

	closeModal() {
		this.modalIsOpen = false;
	}

	formatTextSearch(text: string) {
		if (!text) {
			return '';
		}
		return text.toLowerCase().trim();
	}

	async search() {
		this.students = this.full_students.filter((s) => {
			return (
				this.formatTextSearch(s.full_name).includes(
					this.formatTextSearch(this.searchQuery)
				) ||
				this.formatTextSearch(s.name).includes(
					this.formatTextSearch(this.searchQuery)
				) ||
				this.formatTextSearch(s.unaccent_full_name).includes(
					this.formatTextSearch(this.searchQuery)
				) ||
				this.formatTextSearch(s.unaccent_name).includes(
					this.formatTextSearch(this.searchQuery)
				)
			);
		});
	}

	isAdminAndAccountant() {
		return this.store.getters.isAdminAndAccountant;
	}

	searching() {
		clearInterval(this.typing);
		this.typing = setTimeout(() => {
			this.search();
		}, 1000);
	}

	async submitStudent(student: any) {
		this.inProgress = true;
		const res = await this.setup.createStudents({ student }, '');
		if (res?.data) {
			await this.fetchStudents();
			this.modalIsOpen = false;
			await this.store.dispatch('setAlertMessage', {
				message: 'Thêm học sinh thành công',
			});
		}
		this.inProgress = false;
		if (res.errors) {
			const error =
				res.errors.join('<br/>') ||
				'Đã có lỗi xảy ra. Vui lòng thử lại';
			await this.store.dispatch('setAlertMessage', {
				message: error,
				type: 'danger',
			});
		}
		if (res?.errors_by_field) {
			this.error = res?.errors_by_field['name'].join('<br/>');
		}
	}

	@Watch('status')
	onStatusChange() {
		this.fetchStudents();
	}
	async fetchStaffs() {
		if (this.isAdminAndAccountant()) {
			const res = await this.setup.getEmployees('', null);
			if (res?.data) {
				this.staffsList = res?.data.filter(
					(item: { state: string }) => {
						return item?.state === 'active';
					}
				);
			}
		}
	}
}
