<template>
	<div class="fixed inset-y-0 right-0 flex side-over z-50 w-screen md:w-2/5">
		<div class="h-full w-full flex flex-col bg-white shadow-xl">
			<div class="px-6 py-8 bg-blue-50">
				<div class="flex items-start justify-between">
					<div class="flex items-center" @click="$emit('close')">
						<svg
							fill="none"
							height="24"
							viewBox="0 0 24 24"
							width="24"
							xmlns="http://www.w3.org/2000/svg"
						>
							<path
								clip-rule="evenodd"
								d="M10.5 21C10.5 21 9 21 9 19.5C9 18 10.5 13.5 16.5 13.5C22.5 13.5 24 18 24 19.5C24 21 22.5 21 22.5 21H10.5Z"
								fill="#94A3B8"
								fill-rule="evenodd"
							/>
							<path
								clip-rule="evenodd"
								d="M16.5 12C18.9853 12 21 9.98528 21 7.5C21 5.01472 18.9853 3 16.5 3C14.0147 3 12 5.01472 12 7.5C12 9.98528 14.0147 12 16.5 12Z"
								fill="#94A3B8"
								fill-rule="evenodd"
							/>
							<path
								clip-rule="evenodd"
								d="M7.82454 21C7.61334 20.5739 7.5 20.0687 7.5 19.5C7.5 17.4668 8.51858 15.3758 10.4039 13.9199C9.57914 13.6561 8.61764 13.5 7.5 13.5C1.5 13.5 0 18 0 19.5C0 21 1.5 21 1.5 21H7.82454Z"
								fill="#94A3B8"
								fill-rule="evenodd"
							/>
							<path
								clip-rule="evenodd"
								d="M6.75 12C8.82107 12 10.5 10.3211 10.5 8.25C10.5 6.17893 8.82107 4.5 6.75 4.5C4.67893 4.5 3 6.17893 3 8.25C3 10.3211 4.67893 12 6.75 12Z"
								fill="#94A3B8"
								fill-rule="evenodd"
							/>
						</svg>

						<div
							class="
								text-2xl
								font-bold
								text-neutral-700
								ml-2
								mt-1
							"
						>
							Đổi trị liệu viên
						</div>
					</div>
					<div class="ml-3 h-7 flex items-center">
						<button
							class="
								rounded-md
								text-neutral-400
								hover:text-neutral-700
							"
							type="button"
							@click="$emit('close')"
						>
							<span class="sr-only">Close panel</span>
							<svg
								aria-hidden="true"
								class="h-6 w-6"
								fill="none"
								stroke="currentColor"
								viewBox="0 0 24 24"
								xmlns="http://www.w3.org/2000/svg"
							>
								<path
									d="M6 18L18 6M6 6l12 12"
									stroke-linecap="round"
									stroke-linejoin="round"
									stroke-width="2"
								/>
							</svg>
						</button>
					</div>
				</div>
			</div>
			<div
				class="
					sideover-content
					pt-6
					relative
					flex-1
					px-4
					sm:px-6
					overflow-y-auto
				"
			>
				<div>
					<div>
						<div class="font-semibold text-neutral-600 mt-10">
							Chọn trị liệu viên cần đổi*
						</div>
						<div class="w-full mt-2">
							<div class="bg-white select-tabs">
								<Multiselect
									v-model="staffSelected"
									:allow-empty="false"
									:options="
										staffListMap?.map((s) => {
											return {
												nameSearch: toEnglish(s?.name),
												...s,
											};
										})
									"
									:searchable="true"
									deselect-label="Đã chọn"
									label="nameSearch"
									placeholder="Chọn trị liệu viên"
									selectLabel=""
									track-by="id"
								>
									<template #noResult>
										Không tìm thấy kết quả
									</template>
									<template v-slot:option="props">
										<div
											class="
												flex flex-nowrap
												items-center
											"
										>
											<div>
												{{ props.option.name }}
											</div>
										</div>
									</template>
									<template v-slot:singleLabel="{ option }">
										<div
											class="
												flex flex-nowrap
												items-center
												font-medium
											"
										>
											<div>{{ option.name }}</div>
										</div>
									</template>
								</Multiselect>
							</div>
						</div>
					</div>
					<div>
						<div class="font-semibold text-neutral-600 mt-10">
							Chọn trị liệu viên thay thế*sâfasfsa
						</div>
						<div class="w-full mt-2">
							<div class="bg-white select-tabs">
								<Multiselect
									v-model="staffNewSelected"
									:allow-empty="false"
									:disabled="staffNewOptions.length <= 0"
									:options="
										staffNewOptions?.map((s) => {
											return {
												nameSearch: toEnglish(s?.name),
												...s,
											};
										})
									"
									:searchable="true"
									deselect-label="Đã chọn"
									label="nameSearch"
									placeholder="Chọn trị liệu viên"
									selectLabel=""
									track-by="id"
								>
									<template #noResult>
										Không tìm thấy kết quả
									</template>
									<template v-slot:option="props">
										<div
											class="
												flex flex-nowrap
												items-center
											"
										>
											<div>
												{{ props.option.name }}
											</div>
										</div>
									</template>
									<template v-slot:singleLabel="{ option }">
										<div
											class="
												flex flex-nowrap
												items-center
												font-medium
											"
										>
											<div>{{ option.name }}</div>
										</div>
									</template>
								</Multiselect>
							</div>
						</div>
						<div
							v-if="errorNotTeacher"
							class="text-sienna-500 text-sm mt-2"
						>
							{{ errorNotTeacher }}
						</div>
					</div>
					<div class="flex items-center justify-between mt-4">
						<div
							class="
								text-sm
								flex
								items-center
								gap-2
								text-neutral-500
								cursor-pointer
							"
							@click="isAllStaff = !isAllStaff"
						>
							<Checkbox :value="isAllStaff" />
							<span
								>Hiển thị tất cả TLV (ngay cả khi bị trùng giờ
								dạy)</span
							>
						</div>
					</div>
					<div>
						<div class="font-semibold text-neutral-600 mt-10">
							Ghi chú*
						</div>
						<div class="w-full mt-2">
							<div class="input-group flex items-center">
								<textarea
									v-model="note"
									placeholder="Ghi chú về sự thay đổi này"
								>
								</textarea>
							</div>
						</div>
					</div>
				</div>
				<div :id="`list-dropdown-1`" class="mt-6">
					<div
						class="
							bg-neutral-50
							flex
							items-center
							justify-between
							p-3
							border
							rounded-t
							cursor-pointer
						"
						@click="openList(1)"
					>
						<div>Số buổi đã chọn</div>
						<div class="relative">
							<div class="pr-10">{{ data.length }} buổi</div>
							<div
								:id="`list-dropdown-1-icon`"
								class="
									absolute
									top-1/2
									right-0
									transform
									-translate-y-1/2
									transition-all
									duration-200
								"
							>
								<svg
									fill="none"
									height="6"
									viewBox="0 0 12 6"
									width="12"
									xmlns="http://www.w3.org/2000/svg"
								>
									<path
										d="M10.5 5.25L6 0.75L1.5 5.25"
										stroke="#48566A"
										stroke-linecap="round"
										stroke-linejoin="round"
										stroke-width="1.5"
									/>
								</svg>
							</div>
						</div>
					</div>
					<div
						v-if="data"
						:id="`list-dropdown-1-content`"
						class="px-4 border border-t-0 rounded-b"
					>
						<div
							v-for="(item, index) in data"
							:key="index"
							class="py-4"
						>
							<div class="flex items-center justify-between">
								<div class="text-gray-600 w-1/3">
									<div v-if="item.date">
										{{ $filters.formatDate2(item.date) }}
									</div>
									<div>
										<span>
											{{ item.display_start_slot }}</span
										>
										<span>
											- {{ item.display_end_slot }}</span
										>
									</div>
								</div>
								<div
									v-if="getStudent(item.student_id)"
									class="flex items-center w-1/3"
								>
									<div
										v-if="
											item.student_id &&
											getStudent(item.student_id)
										"
										class="flex items-center"
										@click.stop="
											$router.push(
												`/record/student/${item.student_id}`
											)
										"
									>
										<div>
											<img
												v-if="
													getStudent(item.student_id)
														?.profile_photo?.w200
												"
												:src="
													getStudent(item.student_id)
														?.profile_photo?.w200
												"
												alt=""
												class="
													shadow-sm
													inline-block
													h-9
													w-9
													rounded-full
												"
											/>
											<span
												v-else
												:style="{
													background: getStudent(
														item.student_id
													)?.profile_photo?.default
														?.color,
												}"
												class="
													inline-flex
													items-center
													justify-center
													h-9
													w-9
													rounded-full
												"
											>
												<span
													class="
														font-medium
														leading-none
														text-white text-xs
													"
													>{{
														getStudent(
															item.student_id
														)?.profile_photo
															?.default?.initial
													}}</span
												>
											</span>
										</div>
										<div
											class="
												ml-4
												text-yellow-600
												font-semibold
												truncate
											"
										>
											{{
												getStudent(item.student_id).name
											}}
										</div>
									</div>
								</div>
								<div>
									<div
										v-if="item.staff_ids"
										class="flex flex-col gap-2"
									>
										<div
											v-for="staff_id in item.staff_ids"
											:key="staff_id"
											class="flex items-center"
											@click.stop="
												$router.push(
													`/record/employee/${staff_id}`
												)
											"
										>
											<div v-if="getStaff(staff_id)">
												<img
													v-if="
														getStaff(staff_id)
															?.profile_photo
															?.w200
													"
													:src="
														getStaff(staff_id)
															?.profile_photo
															?.w200
													"
													alt=""
													class="
														shadow-sm
														inline-block
														h-9
														w-9
														rounded-full
													"
												/>
												<span
													v-else
													:style="{
														background:
															getStaff(staff_id)
																?.profile_photo
																?.default
																?.color,
													}"
													class="
														inline-flex
														items-center
														justify-center
														h-9
														w-9
														rounded-full
													"
												>
													<span
														class="
															font-medium
															leading-none
															text-white text-xs
														"
														>{{
															getStaff(staff_id)
																?.profile_photo
																?.default
																?.initial
														}}</span
													>
												</span>
											</div>
											<div
												v-if="getStaff(staff_id)"
												class="
													ml-4
													text-blue-600
													font-semibold
												"
											>
												{{ getStaff(staff_id).name }}
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="px-6 py-4 border-t border-yellow-50">
				<div class="flex justify-end">
					<div>
						<button
							:disabled="!isValidate || inProgress"
							class="button-p text-center px-6"
							type="button"
							@click="submit"
						>
							Xác nhận
						</button>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { defineComponent } from 'vue';
import { $api } from '@/services';
import Multiselect from '@suadelabs/vue3-multiselect';
import { toEnglish } from '@/helper/toEnglish';
import Checkbox from '@/components/elements/checkbox/Checkbox.vue';

export default defineComponent({
	name: 'ChangedStaffForm',

	components: { Checkbox, Multiselect },

	props: {
		data: {
			type: Array,
			required: true,
		},

		staffs_map: {
			type: Object,
			required: true,
		},

		students_map: {
			type: Object,
			required: true,
		},

		staffOptions: {
			type: Array,
			required: true,
		},
	},

	data() {
		return {
			date: new Date(),
			note: '',
			staffSelected: null,
			staffNewSelected: null,
			staffNewOptions: [],
			errorNotTeacher: '',
			inProgress: false,
			isAllStaff: false,
		};
	},

	watch: {
		staffSelected: {
			handler(value) {
				if (value) {
					this.fetchStaffs(value.id);
				}
			},
		},

		isAllStaff() {
			this.fetchStaffs(this.staffSelected.id);
		},
	},

	computed: {
		isValidate() {
			return !!this.note.length;
		},

		staffListMap() {
			return this.staffOptions.map((item) => this.getStaff(item));
		},
	},

	methods: {
		toEnglish,
		async submit() {
			this.inProgress = true;
			const payload = this.data.map((item) => {
				return item.id
					? { id: item.id }
					: {
							date: item.date,
							student_id: item.student_id,
							time_slot_id: item.time_slot_id,
							// eslint-disable-next-line no-mixed-spaces-and-tabs
					  };
			});
			try {
				const {
					data: { data },
				} =
					payload?.length == 1
						? await $api.schedule.changeStaff({
								ids: payload,
								schedule_session: {
									new_staff_id:
										this.staffNewSelected?.id || '',
									old_staff_id: this.staffSelected?.id || '',
									staff_change_note: this.note,
								},
						  })
						: await $api.schedule.changeStaffForOnly(
								this.data[0].student_id,

								{
									schedule_session: {
										new_staff_id:
											this.staffNewSelected?.id || '',
										old_staff_id:
											this.staffSelected?.id || '',
										staff_change_note: this.note,
									},
								},
								this.data[0].id
									? {
											id: this.data[0].id,
									  }
									: {
											date: this.data[0].date,
											time_slot_id:
												this.data[0].time_slot_id,
									  }
						  );
				if (data?.error_messages?.length) {
					await this.$store.dispatch('setAlertMessage', {
						message:
							'Trị liệu viên đã chọn chưa được phân công cho bạn học sinh này',
						type: 'danger',
					});
				} else {
					await this.$store.dispatch('setAlertMessage', {
						message: 'Đổi trị liệu viên thành công',
					});
					this.$emit('success');
				}
			} catch (e) {
				console.log(e);
				await this.$store.dispatch('setAlertMessage', {
					message:
						e?.response?.data?.errors?.join('<br/>') ||
						'Đã có lỗi xảy ra. Vui lòng thử lại',
					type: 'danger',
				});
			} finally {
				this.inProgress = false;
			}
		},

		openList(index) {
			const el = document.getElementById(
				`list-dropdown-${index}-content`
			);
			const icon = document.getElementById(`list-dropdown-${index}-icon`);
			if (el) {
				if (el.classList.contains('hidden')) {
					el.classList.remove('hidden');
					icon?.classList.add('rotate-180');
				} else {
					el.classList.add('hidden');
					icon?.classList.remove('rotate-180');
				}
			}
		},

		getStaff(staff_id) {
			return this.staffs_map[staff_id]?.[0];
		},

		getStudent(student_id) {
			return this.students_map[student_id]?.[0];
		},

		addInputFieldStaff() {
			if (this.staffSelected.length <= 2) {
				this.staffSelected.push(null);
			}
		},

		async fetchStaffs(id) {
			if (this.isAllStaff) {
				await this.fetchAllStaffs();
				return;
			}
			const payload = this.data.map((item) => {
				return item.id
					? {
							id: item.id,
							student_id: item.student_id,

							// eslint-disable-next-line no-mixed-spaces-and-tabs
					  }
					: {
							date: item.date,
							student_id: item.student_id,
							time_slot_id: item.time_slot_id,
							// eslint-disable-next-line no-mixed-spaces-and-tabs
					  };
			});
			try {
				const {
					data: { data },
				} = await $api.schedule.staffListChangeTask({
					ids: payload,
					old_staff_id: id,
				});
				if (data.length) {
					this.staffNewOptions = data;
				} else {
					this.errorNotTeacher =
						'Không có giáo viên phù hợp để thay đổi';
				}
			} catch (err) {
				console.log(err);
			}
		},

		async fetchAllStaffs() {
			try {
				const {
					data: { data },
				} = await $api.staff.getEmployees('');
				if (data.length) {
					this.staffNewOptions = data;
				} else {
					this.errorNotTeacher =
						'Không có giáo viên phù hợp để thay đổi';
				}
			} catch (err) {
				console.log(err);
			}
		},
	},
});
</script>
