<template>
	<div class="fixed inset-y-0 right-0 flex side-over z-50">
		<div class="h-full w-full flex flex-col bg-white shadow-xl">
			<div class="px-6 py-8 bg-blue-50">
				<div class="flex items-start justify-between">
					<div class="flex items-center">
						<svg
							width="24"
							height="24"
							viewBox="0 0 24 24"
							fill="none"
							xmlns="http://www.w3.org/2000/svg"
						>
							<path
								fill-rule="evenodd"
								clip-rule="evenodd"
								d="M1.5 5.5C1.5 4.67157 2.17157 4 3 4H21C21.8284 4 22.5 4.67157 22.5 5.5C22.5 6.32843 21.8284 7 21 7H3C2.17157 7 1.5 6.32843 1.5 5.5Z"
								fill="#94A3B8"
							/>
							<path
								fill-rule="evenodd"
								clip-rule="evenodd"
								d="M3.5 12C3.5 11.1716 4.17157 10.5 5 10.5H19C19.8284 10.5 20.5 11.1716 20.5 12C20.5 12.8284 19.8284 13.5 19 13.5H5C4.17157 13.5 3.5 12.8284 3.5 12Z"
								fill="#94A3B8"
							/>
							<path
								fill-rule="evenodd"
								clip-rule="evenodd"
								d="M6.5 18C6.5 17.1716 7.17157 16.5 8 16.5H16C16.8284 16.5 17.5 17.1716 17.5 18C17.5 18.8284 16.8284 19.5 16 19.5H8C7.17157 19.5 6.5 18.8284 6.5 18Z"
								fill="#94A3B8"
							/>
						</svg>

						<div class="text-2xl font-bold text-neutral-700 ml-2">
							Chỉnh sửa Mục tiêu
						</div>
					</div>
					<div class="ml-3 h-7 flex items-center">
						<button
							type="button"
							class="
								rounded-md
								text-neutral-400
								hover:text-neutral-700
							"
							@click="$emit('close')"
						>
							<span class="sr-only">Close panel</span>
							<svg
								class="h-6 w-6"
								xmlns="http://www.w3.org/2000/svg"
								fill="none"
								viewBox="0 0 24 24"
								stroke="currentColor"
								aria-hidden="true"
							>
								<path
									stroke-linecap="round"
									stroke-linejoin="round"
									stroke-width="2"
									d="M6 18L18 6M6 6l12 12"
								/>
							</svg>
						</button>
					</div>
				</div>
			</div>
			<!-- <div
				class="px-4 sm:px-6"
				v-if="_tabActive !== 1 && _tabActive !== 0"
			></div> -->
			<div
				v-if="_show"
				class="sideover-content mt-6 relative flex-1 px-4 sm:px-6"
			>
				<div class="w-full mb-10">
					<div class="border-b border-neutral-200">
						<nav
							aria-label="Tabs"
							class="-mb-px flex space-x-8 tabs"
						>
							<div
								v-for="(t, index) in tabs"
								:key="index"
								:class="
									tab === index
										? 'border-b-2 text-yellow-700 border-yellow-500'
										: 'text-neutral-500'
								"
								class="
									border-transparent
									hover:text-yellow-700
									hover:border-yellow-500
									whitespace-nowrap
									py-3
									px-1
									border-b-2
									font-semibold
									cursor-pointer
								"
								@click="tab = index"
							>
								<div class="flex items-center gap-2">
									<div>{{ t.name }}</div>
								</div>
							</div>
						</nav>
					</div>
				</div>
				<div v-if="tab === 0">
					<div class="w-full mt-5">
						<div
							class="font-semibold text-neutral-600 mb-2"
							v-if="editable"
						>
							Lĩnh vực*
						</div>
						<div class="select-tabs mb-6" v-if="editable">
							<Multiselect
								deselect-label="Đã chọn"
								track-by="name"
								:options="
									skillsData?.map((s) => {
										return {
											nameSearch: toEnglish(s?.name),
											...s,
										};
									})
								"
								v-model="skillSelected"
								:searchable="true"
								label="nameSearch"
								:allow-empty="false"
								selectLabel=""
								placeholder="Chọn lĩnh vực"
							>
								<template #noResult>
									Không tìm thấy kết quả
								</template>
								<template v-slot:option="props">
									<div class="flex flex-nowrap items-center">
										<div>
											{{ props.option.name }}
										</div>
									</div>
								</template>
								<template v-slot:singleLabel="{ option }">
									<div
										class="
											flex flex-nowrap
											items-center
											font-medium
										"
									>
										<div>{{ option.name }}</div>
									</div>
								</template>
							</Multiselect>
						</div>
						<div
							class="font-semibold text-neutral-600 mb-2"
							v-if="editable"
						>
							Nội dung*
						</div>
						<div class="mb-4" v-if="!isEdit">
							<textarea
								placeholder="Nội dung mục tiêu"
								v-model="_data.name"
								:class="errorName ? 'text-error' : ''"
							>
							</textarea>
							<div
								class="text-sienna-600 text-sm"
								v-if="errorName"
							>
								{{ errorName }}
							</div>
						</div>
						<div
							class="font-semibold text-neutral-600 mb-2"
							v-if="enable_goal_assistance_level && editable"
						>
							Mức độ hỗ trợ*
						</div>
						<div
							class="select-tabs mb-6 w-full mr-8"
							v-if="enable_goal_assistance_level && editable"
						>
							<div
								v-for="(item, ass) in assistanceSelecteds"
								:key="ass"
								class="mb-4"
							>
								<Multiselect
									v-if="assistance_levels.length > 0"
									v-model="assistanceSelecteds[ass]"
									track-by="name"
									:options="
										assistance_levels
											?.filter(
												(a) =>
													!assistanceSelecteds.find(
														(s) =>
															s.order === a.order
													)
											)
											?.map((s) => {
												return {
													nameSearch: toEnglish(
														s?.name
													),
													...s,
												};
											})
									"
									:searchable="true"
									label="nameSearch"
									selectLabel=""
									placeholder="Chọn mức độ hỗ trợ"
									selectedLabel=""
									deselectLabel="Ấn đễ bỏ chọn"
									:class="
										errorAssistanceLevels
											? 'text-error'
											: ''
									"
								>
									<template #noResult>
										Không tìm thấy kết quả
									</template>
									<template v-slot:option="props">
										<div
											class="
												flex flex-nowrap
												items-center
											"
										>
											<div>
												{{ props.option.name }}
											</div>
										</div>
									</template>
									<template v-slot:singleLabel="{ option }">
										<div
											class="
												flex flex-nowrap
												items-center
												font-medium
											"
										>
											<div>{{ option.name }}</div>
										</div>
									</template>
								</Multiselect>
								<div
									class="text-sienna-600 text-sm mt-2"
									v-if="errorAssistanceLevels[ass]"
								>
									{{ errorAssistanceLevels[ass] }}
								</div>
							</div>
							<div
								@click="addAssistanceLevel"
								class="
									font-semibold
									text-yellow-700
									mb-2
									text-sm text-right
									cursor-pointer
								"
							>
								Thêm Mức độ hỗ trợ
							</div>
						</div>
						<div
							class="font-semibold text-neutral-600 mb-2"
							v-if="enable_goal_reason"
						>
							Cơ sở hợp lý*
						</div>
						<div class="mb-4" v-if="enable_goal_reason">
							<textarea
								placeholder="cơ sở hợp lý của mục tiêu"
								v-model="reasonInput"
								:class="errorReason ? 'text-error' : ''"
							>
							</textarea>
							<div
								class="text-sienna-600 text-sm"
								v-if="errorReason"
							>
								{{ errorReason }}
							</div>
						</div>
						<div
							class="font-semibold text-neutral-600 mb-2"
							v-if="enable_goal_strategy"
						>
							Chiến lược*
						</div>
						<div class="mb-4" v-if="enable_goal_strategy">
							<textarea
								placeholder="Chiến lược"
								v-model="strategyInput"
								:class="errorStrategy ? 'text-error' : ''"
							>
							</textarea>
							<div
								class="text-sienna-600 text-sm"
								v-if="errorStrategy"
							>
								{{ errorStrategy }}
							</div>
						</div>
						<div
							class="font-semibold text-neutral-600 mb-2"
							v-if="enable_goal_process"
						>
							Quy trình thực hiện*
						</div>
						<div class="mb-4" v-if="enable_goal_process">
							<textarea
								placeholder="Quy trình thực hiện"
								v-model="processInput"
								:class="errorProcess ? 'text-error' : ''"
							>
							</textarea>
							<div
								class="text-sienna-600 text-sm"
								v-if="errorProcess"
							>
								{{ errorProcess }}
							</div>
						</div>
					</div>
				</div>
				<div v-if="tab === 1">
					<div class="mb-10">
						<div
							v-if="alertShow"
							class="
								bg-yellow-50
								border-l-4 border-yellow-600
								p-4
								rounded-md
							"
						>
							<div class="relative">
								<div class="flex items-center gap-4 pr-8">
									<div>
										<img
											src="@/assets/images/logo/logo.svg"
											alt="luca-logo"
											height="24"
											width="35"
										/>
									</div>
									<div class="pt-1 text-neutral-600 text-sm">
										Chỉnh sửa hoặc xoá hoạt động đã dạy, sẽ
										ảnh hưởng tới kết quả của các buổi học
										trước đó
									</div>
								</div>
								<div
									class="
										top-1
										right-2
										absolute
										cursor-pointer
									"
									@click="alertShow = false"
								>
									<svg
										width="12"
										height="12"
										viewBox="0 0 12 12"
										fill="none"
										xmlns="http://www.w3.org/2000/svg"
									>
										<path
											fill-rule="evenodd"
											clip-rule="evenodd"
											d="M0.292893 0.292893C0.683417 -0.0976311 1.31658 -0.0976311 1.70711 0.292893L6 4.58579L10.2929 0.292893C10.6834 -0.0976311 11.3166 -0.0976311 11.7071 0.292893C12.0976 0.683417 12.0976 1.31658 11.7071 1.70711L7.41421 6L11.7071 10.2929C12.0976 10.6834 12.0976 11.3166 11.7071 11.7071C11.3166 12.0976 10.6834 12.0976 10.2929 11.7071L6 7.41421L1.70711 11.7071C1.31658 12.0976 0.683417 12.0976 0.292893 11.7071C-0.0976311 11.3166 -0.0976311 10.6834 0.292893 10.2929L4.58579 6L0.292893 1.70711C-0.0976311 1.31658 -0.0976311 0.683417 0.292893 0.292893Z"
											fill="#E5A42B"
										/>
									</svg>
								</div>
							</div>
						</div>
						<div class="flex items-center justify-end mt-6">
							<button
								@click="addActivity"
								type="button"
								class="
									button-p
									text-center
									bg-none
									shadow-none
									flex
									items-center
									justify-center
									btn--ghost
									w-fit
								"
							>
								<svg
									class="mr-2 -mt-1"
									width="16"
									height="16"
									viewBox="0 0 16 16"
									fill="none"
									xmlns="http://www.w3.org/2000/svg"
								>
									<path
										fill-rule="evenodd"
										clip-rule="evenodd"
										d="M16 8C16 12.4183 12.4183 16 8 16C3.58172 16 0 12.4183 0 8C0 3.58172 3.58172 0 8 0C12.4183 0 16 3.58172 16 8ZM8.5 4.5C8.5 4.22386 8.27614 4 8 4C7.72386 4 7.5 4.22386 7.5 4.5V7.5H4.5C4.22386 7.5 4 7.72386 4 8C4 8.27614 4.22386 8.5 4.5 8.5H7.5V11.5C7.5 11.7761 7.72386 12 8 12C8.27614 12 8.5 11.7761 8.5 11.5V8.5H11.5C11.7761 8.5 12 8.27614 12 8C12 7.72386 11.7761 7.5 11.5 7.5H8.5V4.5Z"
										fill="#E5A42B"
									/>
								</svg>
								<span>Thêm hoạt động</span>
							</button>
						</div>
						<div
							class="mt-6"
							v-for="(activity, index) in activities"
							:key="index"
						>
							<div class="flex items-center justify-between">
								<div
									class="font-semibold text-neutral-500 mb-2"
								>
									Hoạt động {{ index + 1 }}
								</div>
							</div>
							<textarea
								placeholder="Tên hoạt động"
								v-model="activity.name"
								:class="
									errorActivities[index] ? 'text-error' : ''
								"
							>
							</textarea>
							<div
								class="text-sienna-600 text-sm"
								v-if="errorActivities[index]"
							>
								{{ errorActivities[index] }}
							</div>
							<div class="flex items-center justify-between">
								<div class="text-neutral-500 text-sm">
									Đã dạy
									<span>{{
										activity?.activity_used_count || 0
									}}</span>
									lần
								</div>
								<div class="flex items-center">
									<div
										@click="
											removeActivity(index, activity.id)
										"
										class="
											flex
											items-center
											text-yellow-600
											hover:text-yellow-700
											font-semibold
											cursor-pointer
											text-sm
											mr-8
										"
									>
										<svg
											class="mr-2"
											width="16"
											height="16"
											viewBox="0 0 16 16"
											fill="none"
											xmlns="http://www.w3.org/2000/svg"
										>
											<path
												fill-rule="evenodd"
												clip-rule="evenodd"
												d="M2.5 1C1.94772 1 1.5 1.44772 1.5 2V3C1.5 3.55228 1.94772 4 2.5 4H3V13C3 14.1046 3.89543 15 5 15H11C12.1046 15 13 14.1046 13 13V4H13.5C14.0523 4 14.5 3.55228 14.5 3V2C14.5 1.44772 14.0523 1 13.5 1H10C10 0.447715 9.55229 0 9 0H7C6.44772 0 6 0.447715 6 1H2.5ZM5.5 5C5.77614 5 6 5.22386 6 5.5V12.5C6 12.7761 5.77614 13 5.5 13C5.22386 13 5 12.7761 5 12.5L5 5.5C5 5.22386 5.22386 5 5.5 5ZM8 5C8.27614 5 8.5 5.22386 8.5 5.5V12.5C8.5 12.7761 8.27614 13 8 13C7.72386 13 7.5 12.7761 7.5 12.5V5.5C7.5 5.22386 7.72386 5 8 5ZM11 5.5C11 5.22386 10.7761 5 10.5 5C10.2239 5 10 5.22386 10 5.5V12.5C10 12.7761 10.2239 13 10.5 13C10.7761 13 11 12.7761 11 12.5V5.5Z"
												fill="currentColor"
											/>
										</svg>
										<span class="mt-0.5">XOÁ</span>
									</div>
									<!-- <div
										@click="
											updateOrCreateActivity(activity)
										"
										class="
											flex
											items-center
											text-yellow-600
											hover:text-yellow-700
											font-semibold
											cursor-pointer
											text-sm
										"
									>
										<svg
											class="mr-2"
											width="16"
											height="16"
											viewBox="0 0 16 16"
											fill="none"
											xmlns="http://www.w3.org/2000/svg"
										>
											<path
												d="M11.3333 2H3.33333C2.59333 2 2 2.6 2 3.33333V12.6667C2 13.4 2.59333 14 3.33333 14H12.6667C13.4 14 14 13.4 14 12.6667V4.66667L11.3333 2ZM8 12.6667C6.89333 12.6667 6 11.7733 6 10.6667C6 9.56 6.89333 8.66667 8 8.66667C9.10667 8.66667 10 9.56 10 10.6667C10 11.7733 9.10667 12.6667 8 12.6667ZM10 6H3.33333V3.33333H10V6Z"
												fill="#E5A42B"
											/>
										</svg>
										<span class="mt-0.5"
											>LƯU CHỈNH SỬA</span
										>
									</div> -->
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="px-6 py-4 border-t border-yellow-50">
				<div class="flex justify-end">
					<div>
						<button
							@click="$emit('close')"
							type="button"
							class="
								button-p
								text-center
								w-20
								bg-none
								mr-4
								shadow-none
							"
						>
							Hủy
						</button>
					</div>
					<div>
						<button
							type="button"
							class="button-p text-center w-32"
							@click="submit"
						>
							Lưu chỉnh sửa
						</button>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { Options, Vue } from 'vue-class-component';
import Multiselect from '@suadelabs/vue3-multiselect';
import { Prop, PropSync, Watch } from 'vue-property-decorator';
import { $api } from '@/services';
import { toEnglish } from '@/helper/toEnglish';

@Options({
	name: 'EditGoalSideOver',
	components: { Multiselect },
})
export default class EditGoal extends Vue {
	@PropSync('skills')
	skillsData;
	@PropSync('data')
	_data;
	@PropSync('error')
	_error;

	@PropSync('show')
	_show;

	@PropSync('tabActive')
	_tabActive;

	@Prop('isGroup')
	isGroup;

	tab = 0;

	@Watch('_tabActive', { immediate: true })
	onTabActiveChange(value) {
		if (value === 0 || value === 1) {
			this.tab = value;
		} else {
			this.tab = 0;
		}
	}

	assistance_levels = [];
	tabs = [
		{
			name: 'Nội dung mục tiêu',
		},
		{
			name: 'Hoạt động',
		},
	];
	processInput = '';
	reasonInput = '';
	strategyInput = '';
	skillSelected = null;
	assistanceSelecteds = [''];
	enable_goal_assistance_level = false;
	enable_goal_process = false;
	enable_goal_reason = false;
	enable_goal_strategy = false;

	//
	errorName = '';
	errorStrategy = '';
	errorProcess = '';
	errorReason = '';
	errorAssistanceLevels = '';
	editable = false;

	alertShow = true;

	get org() {
		return this.$store.state.profile.current_org;
	}

	created() {
		if (this.id && this._data) {
			this.fetchGoalDetail();
		}
	}

	mounted() {
		if (this.org) {
			this.enable_goal_assistance_level =
				this.org.enable_goal_assistance_level;
			this.enable_goal_process = this.org.enable_goal_process;
			this.enable_goal_reason = this.org.enable_goal_reason;
			this.enable_goal_strategy = this.org.enable_goal_strategy;
		}
	}

	@Watch('_show')
	onShowChange(value) {
		if (value) {
			this.fetchGoalDetail();
		}
	}

	@Watch('_error')
	onErrorChange(value) {
		if (value) {
			this.errorName = value['name']
				? 'Tên mục tiêu ' + value['name']?.join('</br>>')
				: '';
			this.errorStrategy = value['strategy']
				? 'Chiến lược ' + value['strategy']?.join('</br>>')
				: '';
			this.errorProcess = value['process']
				? 'Quy trình thực hiện ' + value['process']?.join('</br>>')
				: '';
			this.errorReason = value['reason']
				? 'Cơ sở hợp lý ' + value['reason']?.join('</br>>')
				: '';
			this.errorAssistanceLevels.push(value['assistance_level']);
		}
	}

	get id() {
		return String(this.$route.params.id || '');
	}

	async fetchGoalDetail() {
		try {
			this.fetchAssistanceLevel();
			const {
				data: { data },
			} = this.isGroup
				? await $api.groupClasses.goalDetail(
						this.id,
						this._data.id
						// eslint-disable-next-line no-mixed-spaces-and-tabs
				  )
				: await $api.goal.goalDetail(this._data.id, this.id);
			this._data.name = data.name.replace(/<br\s*\/?>/gi, '\n')
			this.processInput = data?.process || '';
			this.reasonInput = data?.reason || '';
			this.strategyInput = data?.strategy || '';
			this.activities = data?.activities || [];
			this.editable = data.editable || false;
			if (data?.skill) {
				this.skillSelected = this.skillsData.find((item) => {
					return item.id === data.skill.id;
				});
			}
			if (data?.assistance_levels) {
				this.assistanceSelecteds = this.assistance_levels.filter(
					(item) => {
						return data.assistance_levels.find(
							(a) => a === item.name
						);
					}
				);
				if (this.assistanceSelecteds.length == 0) {
					this.assistanceSelecteds = [''];
				}
			}
		} catch (e) {
			console.log(e);
		}
	}

	submit() {
		let error = false;
		this.activities.some((activity, index) => {
			if (activity.name?.length <= 0 && index !== 0) {
				error = true;
				this.errorActivities[index] = 'Tên hoạt động không được trống';
				setTimeout(() => {
					this.errorActivities[index] = '';
				}, 2000);
			}
		});
		if (
			this.activities.length === 1 &&
			this.activities[0].name?.length <= 0
		) {
			this.activities = [];
		}
		if (error) {
			return;
		}
		let data = {
			id: this._data.id,
			// name: this._data.name.replace(/\n/g, '<br />'),
			name: this._data.name,
			skill_id: this.skillSelected?.id,
			assistance_level: this.assistanceSelecteds
				?.map((i) => i.order)
				.filter((i) => !!i),
			assistance_levels: this.assistanceSelecteds
				?.map((i) => i.order)
				.filter((i) => !!i),
			process: this.processInput,
			reason: this.reasonInput,
			strategy: this.strategyInput,
			activities: this.activities.map(({ name }) => ({ name })),
		};
		this.$emit('submit', data);
	}

	activities = [];

	errorActivities = [''];

	addActivity() {
		const length = this.activities.length;
		if (length > 0 && this.activities[length - 1].name?.length <= 0) {
			this.errorActivities[length - 1] = 'Tên hoạt động không được trống';

			setTimeout(() => {
				this.errorActivities[length - 1] = '';
			}, 2000);
		} else {
			this.activities.push({
				name: '',
			});
			this.errorActivities.push('');
		}
	}

	async removeActivity(index, id) {
		if (id) {
			try {
				await $api.goal.deleteActivity(id, this.id);
				this.$emit('updatedActivity');
				await this.$store.dispatch('setAlertMessage', {
					message: 'Xoá thành công hoạt động',
				});
			} catch (e) {
				this.$store.dispatch('setAlertMessage', {
					message:
						e?.response?.data?.errors?.join('<br/>') ||
						'Đã có lỗi xảy ra. Vui lòng thử lại',
					type: 'danger',
				});
			}
		}
		this.activities.splice(index, 1);
	}

	async updateOrCreateActivity(activity) {
		try {
			activity?.id
				? await $api.goal.updateActivity(activity.id, this.id, {
						activity,
						// eslint-disable-next-line no-mixed-spaces-and-tabs
				  })
				: await $api.goal.createActivity(this.id, {
						goal_id: Number(this._data.id),
						activity,
						// eslint-disable-next-line no-mixed-spaces-and-tabs
				  });

			this.$emit('updatedActivity');
			await this.$store.dispatch('setAlertMessage', {
				message: activity?.id
					? 'Lưu hoạt động thành công'
					: 'Thêm hoạt động thành công',
			});
		} catch (e) {
			this.$store.dispatch('setAlertMessage', {
				message:
					e?.response?.data?.errors?.join('<br/>') ||
					'Đã có lỗi xảy ra. Vui lòng thử lại',
				type: 'danger',
			});
		}
	}

	addAssistanceLevel() {
		this.assistanceSelecteds.push('');
	}

	toEnglish(string = '') {
		return toEnglish(string);
	}

	async fetchAssistanceLevel() {
		try {
			const res = await $api.org.staffFetchAssistanceLevel();
			if (res?.data) {
				this.assistance_levels = res?.data?.data;
			}
		} catch (e) {
			console.log(e);
		}
	}
}
</script>

<style scoped lang="scss">
@import '../../../assets/styles/responsive';

.side-over {
	width: 100vw;
	padding-left: 0;

	@include media-breakpoint-up(md) {
		width: 600px;
	}
}
.sideover-content {
	height: calc(100vh - 300px);
	overflow-y: auto;
	padding-bottom: 100px;
}
</style>
